import { ReactElement } from "react";
import { twMerge } from "tailwind-merge";

interface OnlyIconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ReactElement;
  overrideClasses?: string;
}
export const OnlyIconButton = ({
  icon,
  overrideClasses,
  ...props
}: OnlyIconButtonProps) => {
  return (
    <button
      type="button"
      className={twMerge(
        "flex h-[42px] w-[42px] items-center justify-center rounded-md transition-all duration-300 hover:bg-lightpurple",
        overrideClasses,
      )}
      {...props}
    >
      {icon}
    </button>
  );
};
