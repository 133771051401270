import { useAuth } from "./../context/AuthContext";
import { FormEvent, useState } from "react";

export default function ContactForm({
  createAndSendMessage,
  onLaunch: closeForm,
}: {
  onLaunch: () => void;
  createAndSendMessage(
    messageText: string,
    userUploadedJson?: Record<string, string>[]  | null,
    hiddenMessage?: boolean,
    type?: string,
  ): void;
}) {
  const { user } = useAuth();
  const [name, setName] = useState(user?.displayName || "");
  const [email, setEmail] = useState(user?.email || "");
  const [ph, setPh] = useState(user?.phoneNumber || "");

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    createAndSendMessage(
      JSON.stringify({ fullName: name, email: email, phoneNumber: ph }),
      null,
      true,
      "contact_form",
    );
    closeForm();
  }
  return (
    <>
      <p className="text-sm">
        Kuration.ai is now ready to work some magic to curate the perfect list
        of accounts. While we deploy the agents, it might take a few hours given
        the complexity... why dont you take a break and get a cup of coffee? We
        will let you know when your list is ready.
      </p>
      <form className="mx-auto my-2 mt-4" onSubmit={handleSubmit}>
        <div className="mt-1 flex flex-col items-start gap-1">
          <label htmlFor="fullName">Full Name:</label>
          <input
            className="borer-2 rounded border-white bg-offwhite p-2 text-dark"
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            id="fullName"
            name="fullName"
          />
        </div>
        <div className="mt-1 flex flex-col items-start gap-1">
          <label htmlFor="email">Email:</label>
          <input
            className="borer-2 rounded border-white bg-offwhite p-2 text-dark"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            id="email"
            name="email"
            required
          />
        </div>
        <div className="mt-1 flex flex-col items-start gap-1">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            className="borer-2 rounded border-white bg-offwhite p-2 text-dark"
            value={ph}
            onChange={(e) => setPh(e.target.value)}
            type="text"
            id="phoneNumber"
            name="phoneNumber"
          />
        </div>
        <br></br>

        <button
          title="Submit"
          className="borer-2 flex-grow rounded border-white bg-offwhite p-2 text-dark"
          type="submit"
          value="Submit"
        >
          Submit
        </button>
      </form>
    </>
  );
}
