import * as React from "react";
const BookIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 3.75C0 3.55109 0.0790176 3.36032 0.21967 3.21967C0.360322 3.07902 0.551088 3 0.75 3H8.247C9.813 3 11.192 3.8 11.998 5.014C12.4082 4.39431 12.9656 3.88598 13.6204 3.53451C14.2752 3.18303 15.0069 2.99939 15.75 3H23.25C23.4489 3 23.6397 3.07902 23.7803 3.21967C23.921 3.36032 24 3.55109 24 3.75V18.813C23.9997 18.9118 23.98 19.0097 23.9419 19.1009C23.9038 19.1921 23.8481 19.2749 23.778 19.3445C23.7079 19.4142 23.6247 19.4693 23.5332 19.5068C23.4418 19.5443 23.3438 19.5634 23.245 19.563L15.563 19.511C15.1656 19.5082 14.7716 19.5843 14.4039 19.7351C14.0362 19.8858 13.7021 20.1081 13.421 20.389L12.531 21.28C12.4613 21.3497 12.3786 21.405 12.2876 21.4427C12.1966 21.4804 12.099 21.4998 12.0005 21.4998C11.902 21.4998 11.8044 21.4804 11.7134 21.4427C11.6224 21.405 11.5397 21.3497 11.47 21.28L10.568 20.379C10.2896 20.1002 9.95899 19.8791 9.59504 19.7283C9.23109 19.5774 8.84097 19.4999 8.447 19.5H0.75C0.551088 19.5 0.360322 19.421 0.21967 19.2803C0.0790176 19.1397 0 18.9489 0 18.75L0 3.75ZM12.75 18.982C13.5532 18.3468 14.549 18.0043 15.573 18.011L22.5 18.058V4.5H15.75C14.9544 4.5 14.1913 4.81607 13.6287 5.37868C13.0661 5.94129 12.75 6.70435 12.75 7.5V18.982ZM11.247 7.497C11.2462 6.70187 10.9298 5.93958 10.3673 5.37762C9.80474 4.81566 9.04213 4.5 8.247 4.5H1.5V18H8.447C9.465 18 10.453 18.346 11.25 18.98L11.247 7.497Z"
      fill={"#FFA928"}
      className={props.className}
    />
  </svg>
);
export default BookIcon;
