const backend_url = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api' : process.env.REACT_APP_BACKEND_URL + "/api";

let ws_url: string;
if (window.location.protocol === "https:") {
  ws_url = backend_url.replace("https", "wss") + "/chat";
} else {
  ws_url = backend_url.replace("http", "ws") + "/chat";
}

console.log("Here are the websockets", ws_url);

// const backend_url = process.env.NODE_ENV === 'development' ? '127.0.0.1:8000/api' : '/api'; //staging

async function fetchProtectedData(
  slug: string,
  tokenPromise: Promise<string> | undefined,
  abortSignal?: AbortSignal,
  method: "GET" | "POST" | "DELETE" = "GET",
  requestBody: { [key: string]: any } | undefined = undefined,
) {
  const token = await tokenPromise;
  let url;
  url = `${backend_url}${slug}`;

  return fetch(url, {
    signal: abortSignal,
    method: method, // "GET" or "POST", "PUT", etc.
    headers: {
      Authorization: `Bearer ${token}`,
      ...(requestBody ? { "Content-Type": "application/json" } : {}),
    },
    ...(requestBody ? { body: JSON.stringify(requestBody) } : {}),
  });
}

const backend_services = { fetchProtectedData, backend_url, ws_url };
export default backend_services;
