// components/layout/Navbar.tsx
import React, { useState } from "react";

import HamBurgerIcon from "./svgs/Hamburger";
import QuestionMarkIcon from "./svgs/QuestionMark";
import { useStateContext } from "../context/StateContext";
import BackIcon from "./svgs/Back";
import { useManualMode } from "../hooks/useManualMode";
import { useTutorialMode } from "../hooks/useTutorialMode";
import KurationLogo from "./svgs/KurationLogo";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const Navbar = () => {
  const { sidebarOpen, setSidebarOpen } = useStateContext();

  const { setChatOpen, chatOpen } = useStateContext();

  // we have a tutorial button on small screens that is accessible from both chat screen or table screen
  // tutorial only opens when chat is open
  // so when tutorial opens we must open chat too
  // and when tutorial closes, we must set chat to whatever it was before.
  // hence we need to save chatOpenBeforeTutorialToggle
  const [chatOpenBeforeTutorialToggle, setChatOpenBeforeTutorialToggle] =
    useState<boolean>(chatOpen);
  const { manualModeOpen } = useManualMode();
  const { listId } = useParams();
  const { tutorialOpen, setTutorialOpen } = useTutorialMode();
  function openTutorial() {
    setChatOpenBeforeTutorialToggle(chatOpen);
    setChatOpen(true);
    setTutorialOpen(true);
  }
  function closeTutorial() {
    setChatOpen(chatOpenBeforeTutorialToggle);
    setTutorialOpen(false);
  }

  if (tutorialOpen && !manualModeOpen) {
    return (
      <nav className="z-40 bg-white px-5 pt-4">
        <div className="flex h-16  w-full items-center justify-start gap-2.5  pb-4">
          <button
            type="button"
            title="back to toolbar"
            className="flex h-[42px] w-[42px] items-center justify-center rounded-md hover:bg-lightpurple"
            onClick={() => {
              closeTutorial();
            }}
          >
            <BackIcon />
          </button>
          <h2 className="text-lg leading-[21.6px] text-black">
            Tutorials and support
          </h2>
        </div>
      </nav>
    );
  }
  if (manualModeOpen) {
    return (
      <nav className="z-40 bg-white px-5 pt-4">
        <ManualModeTopBar />
      </nav>
    );
  }
  return (
    <nav className="z-40 bg-white px-5 pt-4">
      <div className="flex h-16 w-full items-center justify-between border-b border-grey-400 pb-4 transition-all duration-300">
        <button
          title="toggle navbar"
          className="flex h-[42px] w-[42px] items-center justify-center"
          onClick={(e) => {
            e.stopPropagation();
            setSidebarOpen(!sidebarOpen);
          }}
        >
          <HamBurgerIcon />
        </button>
        <div>
          <KurationLogo width={113} height={29} />
        </div>
        <button
          onClick={() => {
            openTutorial();
          }}
          className={twMerge(
            "flex h-[42px] w-[42px] items-center justify-center",
            `${!!listId ? "invisible" : ""}`,
          )}
        >
          <QuestionMarkIcon className="fill-[#000]" />
        </button>
        {/* <!-- Second dropdown container --> */}
        {/* {user && (
          <>
           
          </>
        )} */}
      </div>
    </nav>
  );
};

export default Navbar;

export const ManualModeTopBar = () => {
  const { toggleManualMode } = useManualMode();
  return (
    <div className="flex  w-full items-center justify-start gap-2.5  pb-4">
      <button
        type="button"
        title="back to toolbar"
        className="flex h-[42px] w-[42px] items-center justify-center rounded-md transition-all duration-300 hover:bg-lightpurple"
        onClick={() => {
          toggleManualMode();
        }}
      >
        <BackIcon />
      </button>
      <h2 className="text-lg text-black md:text-xl">Manual settings</h2>
    </div>
  );
};
