import * as React from "react";
const KurationSmallIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 144 180"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <g transform="matrix(1,0,0,1,634.341,-53.961)">
      <g id="Layer_1-2" transform="matrix(11.2114,0,0,11.2114,-634.341,53.961)">
        <g id="Layer_2-2">
          <g id="Layer_1-2-2">
            <path
              d="M12.54,0L9.86,0C9.86,0.143 9.743,0.26 9.6,0.26L9.6,2.94C9.743,2.94 9.86,3.057 9.86,3.2L12.54,3.2C12.54,3.057 12.657,2.94 12.8,2.94L12.8,0.26C12.657,0.26 12.54,0.143 12.54,0Z"
              style={{
                fill: "rgb(0,0,1)",
                fillRule: "nonzero",
              }}
            />
            <path
              d="M6.66,6.4L9.34,6.4C9.34,6.257 9.457,6.14 9.6,6.14L9.6,3.46C9.457,3.46 9.34,3.343 9.34,3.2L6.66,3.2C6.66,3.343 6.543,3.46 6.4,3.46L6.4,6.14C6.543,6.14 6.66,6.257 6.66,6.4Z"
              style={{
                fill: "rgb(0,0,1)",
                fillRule: "nonzero",
              }}
            />
            <path
              d="M6.4,6.66C6.257,6.66 6.14,6.543 6.14,6.4L3.46,6.4C3.46,6.543 3.343,6.66 3.2,6.66L3.2,0.12C3.134,0.12 3.08,0.066 3.08,0L0.08,0C0.08,0.052 0.048,0.1 0,0.12L0,15.88C0.066,15.88 0.12,15.934 0.12,16L3.12,16C3.12,15.934 3.174,15.88 3.24,15.88L3.24,9.34C3.383,9.34 3.5,9.457 3.5,9.6L6.14,9.6C6.14,9.457 6.257,9.34 6.4,9.34L6.4,6.66Z"
              style={{
                fill: "rgb(0,0,1)",
                fillRule: "nonzero",
              }}
            />
            <path
              d="M9.6,9.86C9.457,9.86 9.34,9.743 9.34,9.6L6.66,9.6C6.66,9.743 6.543,9.86 6.4,9.86L6.4,12.54C6.543,12.54 6.66,12.657 6.66,12.8L9.34,12.8C9.34,12.657 9.457,12.54 9.6,12.54L9.6,9.86Z"
              style={{
                fill: "rgb(0,0,1)",
                fillRule: "nonzero",
              }}
            />
            <path
              d="M12.54,12.8L9.86,12.8C9.86,12.943 9.743,13.06 9.6,13.06L9.6,15.74C9.743,15.74 9.86,15.857 9.86,16L12.54,16C12.54,15.857 12.657,15.74 12.8,15.74L12.8,13.06C12.657,13.06 12.54,12.943 12.54,12.8Z"
              style={{
                fill: "rgb(0,0,1)",
                fillRule: "nonzero",
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default KurationSmallIcon;
