import { Link } from "react-router-dom";
import Header from "../../components/Forms/Header";
import SignUp from "../../components/Forms/Signup";
import { LoginAside } from "../login/LoginAside";

export default function SignupPage() {
  return (
    <>
      <div className="flex h-full min-h-screen w-full bg-white">
        <div className="h-full  flex-grow space-y-6  px-4 pt-4 sm:px-6 lg:px-20">
          <Header isLogin={false} />
          <SignUp />
          <p className="mt-3 text-center text-sm text-grey-100">
            Already have an account?
            <Link
              to={"/login"}
              className="pl-1 font-medium text-primary hover:text-primaryhover"
            >
              Login
            </Link>
          </p>
        </div>
        <LoginAside />
      </div>
    </>
  );
}
