import { KurationTool } from "src/models/tools";

export function ToolsList({
  tools,
  setSelectedTool,
}: {
  tools: KurationTool[];
  setSelectedTool: React.Dispatch<React.SetStateAction<KurationTool | null>>;
}) {
  return (
    <div className="flex max-h-[70vh] flex-col gap-1 overflow-auto py-4">
      {tools.length > 0 &&
        (tools as KurationTool[]).map((t) => {
          return (
            <button
              key={t.id}
              onClick={() => {
                setSelectedTool(t);
              }}
              className="flex w-full cursor-pointer items-center gap-3 rounded p-4 outline-none transition-all duration-300 hover:bg-lightpurple focus-visible:bg-lightpurple"
            >
              <div>
                <img
                  src={t.icon}
                  alt={t.name + " icon"}
                  width={30}
                  height={30}
                />
              </div>
              <div className="text-lg">{t.name}</div>
            </button>
          );
        })}
    </div>
  );
}
