import { useEffect, useState } from "react";
import EyeIcon from "src/components/svgs/Eye";

interface InputProps {
  addUserMessage?: (message: string) => void;
  isMessageAllowed: boolean;
  isPasswordEyeDisplayed?: boolean;
  placeholder?: string;
  startText?: string;
  type?: string
}

export default function InputBox({
  addUserMessage = () => { },
  isPasswordEyeDisplayed = false,
  isMessageAllowed,
  placeholder = "",
  startText = "",
  type = "text",
}: InputProps) {
  const [input, setInput] = useState(startText);
  const [click, setClick] = useState(false);
  const [inputType, setInputType] = useState(type);

  useEffect(() => {
    setInput(startText);
  }, [startText]); // Update input when startText changes

  function handleMessageAdding() {
    if (!isMessageAllowed) {
      return;
    }
    addUserMessage(input.trim());
  }

  function handleClick() {
    setClick(!click);
    if (click) {
      setInputType("password");
    } else {
      setInputType("text");
    }
  }

  return (
    <div className="relative">
      <input
        type={inputType}
        placeholder={placeholder}
        value={input}
        disabled={!isMessageAllowed}
        onChange={(e) => setInput(e.target.value)}
        onBlur={handleMessageAdding}
        className={`peer inline-flex max-h-14  shadow-[2px_2px_20px_0px_#00000014] w-full resize-none items-center justify-center gap-2.5 rounded-md border  py-3.5 pl-3 pr-[12px] mr-[32px]  outline-none md:pl-4 ${isMessageAllowed
          ? "border-neutral-400 bg-white hover:border-purple"
          : "placeholder-purple bg-lightpurple"}
        `}
      ></input>
      {isPasswordEyeDisplayed &&
        <button 
          className="absolute inset-y-0 end-0 flex items-center z-20 px-3 "
          onClick={handleClick}
        >
          <EyeIcon fill={click ? "#B0B0B0" : "#4F4F4F"}/>
        </button>
      }
    </div >
  );
}
