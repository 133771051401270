import { twMerge } from "tailwind-merge";
import { FormatOption } from "./CustomFilterForm";

interface FormSelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: { id: string; name: string }[];
  val: string;
  setVal: (v: string) => void;
  overrideClasses?: string;
}
export const FormSelect = ({
  options,
  val,
  overrideClasses,
  setVal,
  ...props
}: FormSelectProps) => {
  return (
    <select
      value={val}
      className={twMerge(
        `block h-10 w-full rounded-lg border border-neutral-200 bg-gray-50 p-2.5 text-sm text-gray-900 outline-none outline-1 focus-within:border-purple hover:border-purple focus:border-purple  `,
        overrideClasses,
      )}
      onChange={(e) => {
        setVal(e.target.value as FormatOption["id"]);
      }}
      {...props}
    >
      <option value={""} className="h-10 bg-lightpurple p-4">
        Not selected
      </option>
      {options.map((o) => {
        return (
          <option key={o.id} value={o.id} className="h-10 bg-lightpurple p-4">
            {o.name}
          </option>
        );
      })}
    </select>
  );
};
