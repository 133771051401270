import Lottie from "react-lottie";
import animationData from "../lotties/base.json";

export default function FullScreenLottie() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="absolute inset-0 z-10 flex flex-col items-center justify-center gap-3 bg-white">
      <Lottie options={defaultOptions} height={400} width={400} />
    </div>
  );
}
