import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { IconButton } from "src/components/elements/IconButton";
import ChatIcon from "src/components/svgs/Chat";
import { fetchTools } from "src/components/utils";
import { useAuth } from "src/context/AuthContext";
import { useStateContext } from "src/context/StateContext";
import {} from "src/models/data";
import { FiltersMain } from "./FiltersMain";
import { KurationFilterDetails } from "./KurationFilterDetails";
import BackIcon from "src/components/svgs/Back";
import { CustomFilterForm } from "./CustomFilterForm";
import { CustomTool, KurationTool, Tool } from "src/models/tools";
import useChatDetails from "src/hooks/useChatDetails";
import useDefaultTable from "src/hooks/useChatTable";
import { useParams } from "react-router-dom";
import useAllCustomTools from "src/hooks/useAllCustomTools";
import { CustomFilterDetails } from "./CustomFilterDetails";

export function FiltersLibrary() {
  const { setFiltersOpen } = useStateContext();
  const { user } = useAuth();
  const [showCreateCustomFilter, setShowCreateCustomFilter] = useState(false);
  const [selectedTool, setSelectedTool] = useState<Tool | null>(null);
  const { chatId } = useParams();
  const { chatDetails } = useChatDetails(chatId ?? null);

  const tableId = chatDetails?.table_id;

  const { data: defaultTable } = useDefaultTable(tableId);

  const columns = defaultTable?.column_list;
  const { loading: customToolsLoading, data: customTools } =
    useAllCustomTools();

  const {
    isLoading,
    error,
    refetch,
    data: tools,
  } = useQuery({
    queryKey: ["tools"],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return user && fetchTools(user?.getIdToken());
    },
  });

  return (
    <div className="flex h-full flex-1 flex-col">
      <div className="flex items-start border-b border-neutral-300 py-9 pl-6">
        <IconButton
          icon={<ChatIcon />}
          variant="outline"
          onClick={() => {
            setFiltersOpen(false);
            setShowCreateCustomFilter(false);
          }}
          text={"Back to the chat"}
          overrideClasses="w-max gap-2 border-none bg-grey-600 p-3 text-base font-normal text-neutral-900"
        />
      </div>
      {(!!selectedTool || !!showCreateCustomFilter) && (
        <div className="flex h-[72px] w-full flex-col items-start justify-center gap-[33px] border-b border-neutral-300 bg-white py-6 pl-[23px] pr-6">
          <div className="flex  items-center justify-start gap-3">
            <button
              className="flex h-[42px] w-[42px] items-center justify-center rounded-md transition-all duration-300 hover:bg-lightpurple"
              onClick={() => {
                setSelectedTool(null);

                setShowCreateCustomFilter(false);
              }}
            >
              <BackIcon className=" h-4 w-4" />
            </button>

            <div className="leading-none">Enrichment library</div>
          </div>
        </div>
      )}
      {showCreateCustomFilter ? (
        <CustomFilterForm
          columns={columns}
          onSuccess={() => {
            setShowCreateCustomFilter(false);
          }}
        />
      ) : (
        <div>
          {!selectedTool ? (
            <FiltersMain
              isLoading={isLoading}
              customToolsLoading={customToolsLoading}
              customTools={customTools}
              error={error}
              tools={tools}
              retryTools={() => {
                refetch();
              }}
              setShowCreateCustomFilter={setShowCreateCustomFilter}
              setSelectedTool={setSelectedTool}
            />
          ) : (
            <div className="flex flex-1 flex-col">
              {Object.hasOwn(selectedTool, "name") ? (
                <KurationFilterDetails
                  selectedTool={selectedTool as KurationTool}
                />
              ) : (
                <CustomFilterDetails
                  selectedTool={selectedTool as CustomTool}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
