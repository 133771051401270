import StarsIcon from "src/components/svgs/Stars";

export const FilterCard = ({
  name,
  iconUrl,
}: {
  name: string;
  iconUrl?: string;
}) => {
  return (
    <div className="flex h-[110px] max-w-[248px] flex-col items-start justify-between gap-6 rounded-md border-b-4 border-neutral-100 bg-neutral-100 px-3 py-4 transition-all duration-300  hover:border-purple">
      <div className="inline-flex h-6 w-6 items-center justify-center px-[1.49px] pb-[2.25px] pt-[2.12px]">
        <div className="relative">
          {iconUrl ? (
            <img className="h-[19.63px] w-[21.02px]" src={iconUrl} alt={name} />
          ) : (
            <div className="relative h-6 w-6">
              <StarsIcon fill="#e08588" />
            </div>
          )}
        </div>
      </div>
      <div className="h-min w-full truncate text-neutral-900">{name}</div>
    </div>
  );
};
