import { useSearchParams } from "react-router-dom";
import { useStateContext } from "src/context/StateContext";

export const useManualMode = () => {
  const [urlParams, setUrlParams] = useSearchParams();
  const { setChatType } = useStateContext();
  const manualModeOpen = urlParams.get("manual") === "true";
  const toggleManualMode = () => {
    if (manualModeOpen) {
      setChatType("refine");
      setUrlParams((params) => {
        params.delete("manual");
        return params;
      });
    } else {
      setChatType("manual_search");
      setUrlParams((params) => {
        params.set("manual", "true");
        return params;
      });
    }
  };
  return { manualModeOpen, toggleManualMode };
};
