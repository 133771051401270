import { useAuth } from "../../context/AuthContext";
import BackIcon from "src/components/svgs/Back";
import InputBox from "./InputBox";
import { useState } from "react";
import { RocketIcon } from "src/components/svgs/RocketIcon";
import { reauthenticate } from "./AccountProvider";
import { updatePassword } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";

export default function ChangePassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { user } = useAuth();

  async function handleChangePassword() {
    if (newPassword.length < 6 || confirmPassword.length < 6)
      return toast.error("Invalid Password. Password should be at least 6 characters");
    if (newPassword !== confirmPassword) {
      return toast.error("Passwords don't match. Please ensure that the password and confirm password are the same.");
    }
    if (user) {
      await reauthenticate(user);
      updatePassword(user, newPassword)
        .then(() => {
          return toast.success("Password Changing success");
        })
        .catch((error) => {
          console.log(error);
          return toast.error("Password Changing Failed. Try again.");
        })
    }
  }

  return (
    <div className="grid auto-rows-max gap-y-6 w-[534px]">
      <div className="flex gap-x-4">
        <a href="/profile/account"><BackIcon /></a>
        <div>Back to account</div>
      </div>
      <div className="text-3xl">Change your email address</div>
      <div>
        <div>New password</div>
        <div className="w-[387px]">
          {user && <InputBox type={"password"} addUserMessage={setNewPassword} isMessageAllowed={true} isPasswordEyeDisplayed={true}/>}
        </div>
      </div>
      <div className="w-[387px]">
        <div>Confirm password</div>
        {user && <InputBox type={"password"} addUserMessage={setConfirmPassword} isMessageAllowed={true} isPasswordEyeDisplayed={true}/>}
      </div>
      <div className="flex justify-end w-[387px]">
        <button
          className="flex h-12 items-center justify-center gap-1.5 rounded-md  bg-primary px-6 text-base leading-[19.2px] text-white hover:bg-purpleHover"
          onClick={handleChangePassword}
        >
          <RocketIcon />
          <div>Change Password</div>
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}

