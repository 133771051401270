import * as React from "react";
const ColumnsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.75 2H9.25C10.216 2 11 2.784 11 3.75V20.25C11 20.7141 10.8156 21.1592 10.4874 21.4874C10.1592 21.8156 9.71413 22 9.25 22H3.75C3.28587 22 2.84075 21.8156 2.51256 21.4874C2.18437 21.1592 2 20.7141 2 20.25V3.75C2 2.784 2.784 2 3.75 2ZM14.75 2H20.25C21.216 2 22 2.784 22 3.75V20.25C22 20.7141 21.8156 21.1592 21.4874 21.4874C21.1592 21.8156 20.7141 22 20.25 22H14.75C14.2859 22 13.8408 21.8156 13.5126 21.4874C13.1844 21.1592 13 20.7141 13 20.25V3.75C13 2.784 13.784 2 14.75 2ZM3.5 3.75V20.25C3.5 20.388 3.612 20.5 3.75 20.5H9.25C9.3163 20.5 9.37989 20.4737 9.42678 20.4268C9.47366 20.3799 9.5 20.3163 9.5 20.25V3.75C9.5 3.6837 9.47366 3.62011 9.42678 3.57322C9.37989 3.52634 9.3163 3.5 9.25 3.5H3.75C3.6837 3.5 3.62011 3.52634 3.57322 3.57322C3.52634 3.62011 3.5 3.6837 3.5 3.75ZM14.5 3.75V20.25C14.5 20.388 14.612 20.5 14.75 20.5H20.25C20.3163 20.5 20.3799 20.4737 20.4268 20.4268C20.4737 20.3799 20.5 20.3163 20.5 20.25V3.75C20.5 3.6837 20.4737 3.62011 20.4268 3.57322C20.3799 3.52634 20.3163 3.5 20.25 3.5H14.75C14.6837 3.5 14.6201 3.52634 14.5732 3.57322C14.5263 3.62011 14.5 3.6837 14.5 3.75Z"
      fill="black"
    />
  </svg>
);
export default ColumnsIcon;
