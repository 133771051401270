import * as React from "react";
const ToolbarToggleOpen = (props: React.SVGProps<SVGSVGElement>) => {
  const classes = props.className;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="32"
      fill="none"
      viewBox="0 0 13 32"
      {...props}
    >
      <g>
        <path
          fill="#D1D1D1"
          className={classes}
          d="M11.201 28.163C12.04 29.949 10.735 32 8.761 32c-1.07 0-2.038-.634-2.467-1.614L1.052 18.405a6 6 0 010-4.81l5.242-11.98A2.694 2.694 0 018.762 0c1.973 0 3.277 2.051 2.44 3.837l-4.508 9.616a6 6 0 000 5.094l4.507 9.616z"
        ></path>
      </g>
    </svg>
  );
};
export default ToolbarToggleOpen;
