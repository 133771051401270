import * as React from "react";
const ToolbarToggleClose = (props: React.SVGProps<SVGSVGElement>) => {
  const classes = props.className;
  return (
    <svg
      width={13}
      height={32}
      viewBox="0 0 13 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.79879 3.83741C0.96139 2.05097 2.26511 0 4.23809 0C5.3084 0 6.2772 0.63361 6.70621 1.61419L11.9479 13.5951C12.6186 15.1282 12.6186 16.8718 11.9479 18.4049L6.70621 30.3858C6.27721 31.3664 5.3084 32 4.23809 32C2.26511 32 0.96139 29.949 1.79879 28.1626L6.30628 18.5466C7.06261 16.9331 7.06261 15.0669 6.30628 13.4534L1.79879 3.83741Z"
        fill="#111010"
        className={classes}
      />
    </svg>
  );
};
export default ToolbarToggleClose;
