import { useQuery } from "@tanstack/react-query";
import { useAuth } from "src/context/AuthContext";
import backend_services from "src/services/backend_service";
import { SimpleSpinner } from "../elements/Spinner";
import { PreCuratedListItem } from "src/models/preCuratedList";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { numberFormatter } from "../utils";

export function PreCuratedLists() {
  return (
    <div className="grid h-full grid-rows-[min-content,1fr] gap-10">
      <p className="text-4xl font-normal text-neutral-900">Pre-curated Lists</p>
      <div className="flex h-full w-full flex-1 items-start justify-center overflow-auto">
        <PreCuratedListView />
      </div>
    </div>
  );
}

const PreCuratedListView = () => {
  const { user } = useAuth();
  const [chatCreationLoading, setChatCreationLoading] = useState(false);
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["pre_curated_lists"],
    refetchOnWindowFocus: true,
    queryFn: () => {
      return (
        user &&
        backend_services
          .fetchProtectedData(`/pre_curated_lists/`, user?.getIdToken())
          .then((res) => res.json())
      );
    },
  });
  // console.log({ isLoading, error, data });
  if (isLoading || chatCreationLoading) {
    return (
      <div className="flex h-full items-center">
        <SimpleSpinner radius={20} overrideClasses="border-black" />
      </div>
    );
  }
  if (error) {
    return (
      <div className="flex h-full items-center">
        <button
          onClick={() => {
            refetch();
          }}
        >
          Error fetching companies. <strong>Retry?</strong>
        </button>
      </div>
    );
  }
  return (
    <div className="grid max-h-full w-full grid-cols-[repeat(auto-fill,292px)] gap-6 overflow-auto p-1">
      {data &&
        Array.isArray(data) &&
        (data as PreCuratedListItem[])?.map((item) => {
          return (
            <PreCuratedCard
              key={item._id}
              item={item}
              onSelection={() => {
                setChatCreationLoading(true);
              }}
            />
          );
        })}
    </div>
  );
};

const PreCuratedCard = ({
  item,
}: {
  item: PreCuratedListItem;
  onSelection: () => void;
}) => {
  const navigator = useNavigate();
  function handleGetStarted() {
    navigator(`/list/${item._id}`);
  }

  return (
    <div
      key={item._id}
      className="group inline-flex w-[292px] flex-col items-start justify-start gap-6 rounded-md bg-white p-4 shadow-neutral-800 drop-shadow-md"
    >
      <div className="flex flex-col items-start justify-start gap-2">
        <div title={item.name} className=" flex items-center justify-between gap-4 w-full">
          <div className="max-w-[260px] flex-1 group-hover:max-w-[200px] truncate font-['Helvetica'] text-2xl font-normal text-neutral-900">
            {item.name}
          </div>
          <div className="hidden group-hover:block text-xl font-normal text-neutral-900">{numberFormatter.format(item.numItems)}</div>
        </div>
        <div className="flex flex-col items-start justify-start gap-3">
          <p
            title={item.description}
            className="w-[256px] truncate font-['Helvetica'] text-sm font-normal text-neutral-900"
          >
            {item.description}
          </p>
          <div className="flex h-[134px] flex-col items-start justify-start gap-0.5">
            {item.top_4?.slice(0, 4)?.map((top, index) => (
              <div
                key={index.toString() + top.icon}
                className="inline-flex w-[260px] items-start justify-start gap-2 px-1 py-2"
              >
                <div className="relative h-4 w-4">
                  <img src={top.icon} alt={top.name} width={16} />
                </div>
                <div className="flex h-4  items-center justify-start gap-0.5">
                  <div className="max-w-[200px] truncate text-base font-normal text-neutral-900">
                    {top.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          handleGetStarted();
        }}
        className="inline-flex h-10 w-[260px] items-center justify-center gap-2 rounded-md bg-indigo-500 px-3.5 py-2"
      >
        <div className="font-['Helvetica'] text-base font-normal text-white">
          Get Started
        </div>
      </button>
    </div>
  );
};
