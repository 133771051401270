import KurationLogo from "../svgs/KurationLogo";

export default function Header({ isLogin }: { isLogin: boolean }) {
  return (
    <div className="mb-4 md:mb-6">
      <div className="mt-4 flex justify-center">
        <KurationLogo className="max-w-[200px] lg:max-w-[300px]" />
      </div>
      <h2 className="mt-3 text-center text-2xl font-normal leading-[35.2px] text-black lg:text-4xl lg:leading-[55.2px]">
        Meet your new Business <br></br>Research AI Agent
      </h2>
      <p className="text-center text-base font-normal  text-grey-100 md:text-2xl md:leading-[33.6px]">
        {isLogin ? "Login to your account" : "Create a new account"}
      </p>
    </div>
  );
}
