import { useTutorialMode } from "../../hooks/useTutorialMode";
import InputContainer, {
  InputWithSuggestion,
} from "../../components/elements/InputContainer";
import {
  ChatStarText,
  HelpChatActions,
  ProductTourCard,
} from "./chatToolbarElements";
import { CreateAndSendMessage } from "src/models/data";

export function NoChatOpened({
  createAndSendMessage,
}: {
  createAndSendMessage: CreateAndSendMessage;
}) {
  const { tutorialOpen } = useTutorialMode();

  return (
    <>
      <div className={`${!tutorialOpen ? "hidden md:block" : ""}`}>
        <ProductTourCard />
      </div>
      <div className={`mb-10 hidden  md:block`}>
        <ChatStarText />
      </div>

      <div className={`mt-6 hidden md:block`}>
        <InputWithSuggestion
          addUserMessage={(text) => {
            createAndSendMessage(text);
          }}
          isMessageAllowed={true}
          placeholder="Ask anything—your imagination is the only limit!"
        />
      </div>
      <div className={`${tutorialOpen ? "hidden md:block" : ""}`}>
        <div className="py-2.5 text-2xl font-semibold leading-[28.8px] text-black md:hidden">
          How you want to start?
        </div>
        <div className={`sticky bottom-0 md:hidden`}>
          <div className="relative my-6 flex items-center justify-center">
            <div className="absolute z-0 h-[1px] w-full -translate-y-1/2 bg-grey-400"></div>
            <div className="z-10 h-7 w-6 bg-white p-1 text-sm text-grey-200">
              Or
            </div>
          </div>
          <InputContainer
            isMessageAllowed={true}
            placeholder="Ask anything—your imagination is the only limit!"
            addUserMessage={(text) => {
              createAndSendMessage(text);
            }}
          />
          <div className="mb-6 mt-4">
            <ChatStarText />
          </div>
        </div>
      </div>
      <div className={`mt-6 ${!tutorialOpen ? "hidden md:block" : ""}`}>
        <div className="inline-flex h-12 w-full items-center justify-start gap-2.5 py-2.5 pr-2.5">
          <div className="w-96 text-xl font-normal leading-7 text-neutral-900">
            <div className={`${tutorialOpen ? "hidden md:block" : ""}`}>
              Tutorials and support
            </div>
            <h3
              className={`${
                tutorialOpen ? "block md:hidden" : "hidden"
              } text-base leading-[19.2]`}
            >
              Guidance and assistance:
            </h3>
          </div>
        </div>
        <HelpChatActions />
      </div>
    </>
  );
}
