import { DpeToolState } from "src/models/Dpe";
import { KurationFilterHead } from "../filters/KurationFilterDetails";
import { sentenceCase } from "src/components/utils";
import { XCircleIcon } from "@heroicons/react/24/outline";
import {
  CustomFilterHead,
  CustomToolFieldsDetails,
} from "../filters/CustomFilterDetails";
import { KurationToolColumn } from "src/hooks/useChatTable";

export function ToolDpe({
  dpeState,
}: {
  dpeState: DpeToolState;
}) {
  if (
    dpeState.cell.type === "custom_column" &&
    dpeState.cell.status === "skipped"
  ) {
    return (
      <div>
        Cell skipped.
        <div className="mb-5 mt-9 text-xl">Failed condition:</div>
        <div className="flex flex-wrap gap-2">
          <span className="rounded-md bg-gray-200 px-2">
            {dpeState.cell.skip_reason?.condition?.display ||
              dpeState.cell.skip_reason?.condition.key}
          </span>
          <strong>{dpeState.cell.skip_reason?.condition?.type}</strong>
          <span className="rounded-md bg-gray-200 px-2">
            {dpeState.cell.skip_reason?.condition?.value}
          </span>
        </div>
        <div className="my-3 text-xl">Evaluated condition:</div>
        <div className="flex gap-2 ">
          <span className="rounded-md bg-gray-200 px-2">
            {dpeState.cell.skip_reason?.data_value}
          </span>
          <strong>{dpeState.cell.skip_reason?.condition.type}</strong>{" "}
          <span className="rounded-md bg-gray-200 px-2">
            {dpeState.cell.skip_reason?.condition_value}
          </span>
          <span className="ml-4">
            <XCircleIcon width={20} className="text-red-500" />
          </span>
        </div>
      </div>
    );
  }
  return (
    <div>
      Output generated using:
      {dpeState.column.type === "tool_column" ? (
        <KurationFilterHead selectedTool={dpeState.column.tool} />
      ) : (
        <CustomFilterHead selectedTool={dpeState.column.custom_tool} />
      )}
      {dpeState.column.type === "tool_column" ? (
        <div>
          <div className="mb-5 mt-9">Input used:</div>
          <ul className="m-0 list-none rounded-md bg-gray-200 px-4 py-6 marker:ml-2">
            {dpeState.column.tool.fields.map((f) => {
              return (
                <li className="mt-4">
                  <div>
                    <div>
                      <span className="text-neutral-700">
                        {sentenceCase(f.id, "_")} -{" "}
                      </span>{" "}
                      <span className="text-neutral-400">{f.description}</span>
                    </div>
                    <div>
                      <p>
                        {
                          (dpeState.column as KurationToolColumn).column_data[
                            f.id
                          ]
                        }
                      </p>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <CustomToolFieldsDetails selectedTool={dpeState.column.custom_tool} />
      )}
    </div>
  );
}
