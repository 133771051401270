import { useState } from "react";
import { usePreCuratedListDetail } from "src/hooks/usePreCuratedCompanies";
import { IconButton } from "../elements/IconButton";
import PlusIcon from "../svgs/Plus";
import { FunnelIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useSearchParams } from "react-router-dom";
import { useListFilterQuery } from "src/hooks/useSearchQuery";
import { FilterFields, FilterFormField, getUpdatedForm } from "./FilterFields";



const filterOptions: Array<{ id: FilterFormField["type"]; name: string }> = [
  { id: "equals", name: "Equals" },
  { id: "notEquals", name: "Not Equals" },
  { id: "startsWith", name: "Starts With" },
  { id: "contains", name: "Contains" },
  { id: "exists", name: "Exists" },
  { id: "greaterThan", name: "Greater Than" },
  { id: "lessThan", name: "Less Than" },
];
export function ListFilters() {
  const { data } = usePreCuratedListDetail();
  const [, setSearchParams] = useSearchParams();
  const listFilter = useListFilterQuery();

  const [filterForm, setFilterForm] = useState<FilterFormField[]>(listFilter);

  function handleFormUpdate(
    f: FilterFormField,
    k: keyof FilterFormField,
    v: FilterFormField["key"],
  ) {
    setFilterForm((prev) =>
      getUpdatedForm({
        prev,
        updatedField: f,
        k,
        v,
      }),
    );
  }
  function submit(filterForm: FilterFormField[]) {
    // console.log("Submitted", filterForm);
    const filterToSend: { [key: string]: any }[] = [];
    filterForm.forEach((f) => {
      if (f.type === "greaterThan" || f.type === "lessThan") {
        f.value = parseInt(f.value as string);
      }
      if (f.type === "exists") {
        f.value = f.value === "true";
      }
      filterToSend.push(f);
    });
    if (false) {
      // console.log(searchParams);
    }
    const encoded = encodeURIComponent(JSON.stringify(filterToSend));
    setSearchParams((prev) => {
      prev.set("filter", encoded);
      return prev;
    });
  }
  function handleFilterRemove(f: FilterFormField) {
    setFilterForm((prev) => {
      const updatedFilters = [...prev.filter((ff) => ff.id !== f.id)];
      submit(updatedFilters);
      return updatedFilters;
    });
  }
  // console.log(filterForm);
  return (
    <div className="px-4 py-8">
      <div className="flex items-center justify-center">
        <div className="text-3xl">List filters</div>
        <IconButton
          icon={<TrashIcon width={20} className="text-black" />}
          overrideClasses="ml-auto h-[42px] w-[42px]  gap-0 border-none p-1.5"
          variant="outline"
          text={<></>}
          onClick={() => {
            const updatedFilters: FilterFormField[] = [];
            setFilterForm((prev) => updatedFilters);
            submit(updatedFilters);
          }}
        />
      </div>
      <div className="mt-12">
        <FilterFields
          filterForm={filterForm}
          filterOptions={filterOptions}
          handleFormUpdate={handleFormUpdate}
          handleFilterRemove={handleFilterRemove}
          cols={(data?.columns || []).map((i) => ({ id: i, name: i }))}
        />
      </div>
      <div>
        <IconButton
          overrideClasses="max-w-max"
          icon={<PlusIcon className="fill-purpleHover" />}
          text={"Add filter"}
          variant="outline"
          onClick={() => {
            setFilterForm((prev) => [
              ...prev,
              { id: Math.random().toString(), key: "", type: "", value: "" },
            ]);
          }}
        />
      </div>
      {filterForm.length > 0 && (
        <div className="mt-12 flex justify-center">
          <IconButton
            overrideClasses="max-w-max"
            icon={<FunnelIcon width={20} className="fill-white" />}
            text={"Run filter"}
            variant="fill"
            onClick={() => {
              submit(filterForm);
            }}
          />
        </div>
      )}
    </div>
  );
}
