import { useState, useRef } from "react";
import { getColumnDisplayName } from "src/components/utils";
import { Column } from "src/hooks/useChatTable";
import { twMerge } from "tailwind-merge";

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  columns: Column[];
  val: string;
  setVal: (v: string) => void;
  overrideClasses?: string;
}
export const ColumnsTextArea = ({
  columns,
  val,
  overrideClasses,
  setVal,
  ...props
}: TextAreaProps) => {
  const [colsPopup, setColsPopup] = useState({
    hidden: true,
    filter: "",
    line: 0,
  });
  const [cursorPosition, setCursorPosition] = useState(0);

  const textRef = useRef<HTMLTextAreaElement>(null);
  function handleKeyUp(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    // check if last instance of slash still has some non-space characters next to it?
    // then show list of columns and use this text to filter columns
    const val = textRef?.current?.value || "";
    const selectionStart = e.currentTarget?.selectionStart || 0;
    setCursorPosition(selectionStart);

    const textFromStartToSelection = val.slice(0, selectionStart) || "";
    const textFromLastInstanceOfSlashToSelection = textFromStartToSelection
      .slice(textFromStartToSelection.lastIndexOf("/") || 0)
      .trimStart();
    if (
      textFromLastInstanceOfSlashToSelection.length > 0 &&
      textFromLastInstanceOfSlashToSelection.includes("/") &&
      !textFromLastInstanceOfSlashToSelection.includes(" ")
    ) {
      setColsPopup({
        hidden: false,
        filter: textFromLastInstanceOfSlashToSelection.replace("/", ""),
        line: selectionStart < 51 ? 0 : 1,
      });
    } else {
      // if / to selection has a space, then no need to show columns
      setColsPopup({ hidden: true, filter: "", line: 0 });
    }
  }
  const filteredColumns = columns?.filter((c) =>
    getColumnDisplayName(c)
      .split(" ")
      .join("_")
      .toLowerCase()
      .startsWith(colsPopup.filter.toLowerCase()),
  );
  return (
    <div className="group relative">
      <textarea
        value={val}
        onChange={(e) => {
          setVal(e.target.value);
        }}
        className={twMerge(
          "inline-flex w-full resize-none items-center justify-center gap-2.5 rounded-md border border-neutral-200   py-3.5 pl-3  pr-11 text-neutral-900 outline-none  placeholder:text-neutral-400 focus-within:border-purple  hover:border-purple  md:pl-4",
          overrideClasses,
        )}
        ref={textRef}
        {...props}
        onKeyUp={(e) => handleKeyUp(e)}
      />
      {!colsPopup.hidden && (filteredColumns.length || 0) > 0 && (
        <div>
          <div
            style={{ top: colsPopup.line * 20 + 40 }}
            className={`absolute left-1 z-10 hidden max-h-44 w-full overflow-auto rounded-md border border-neutral-400 bg-white p-4 shadow-lg group-focus-within:block`}
          >
            {filteredColumns.map((c) => (
              <button
                key={c.id}
                tabIndex={0}
                onClick={(e) => {
                  const colName = getColumnDisplayName(c)
                    .split(" ")
                    .join("_")
                    .toLowerCase();
                  const textFromStartToSelection =
                    val.slice(0, cursorPosition) || "";
                  const lastIndexOfFilter =
                    textFromStartToSelection.lastIndexOf(
                      `/${colsPopup.filter}`,
                    ) || 0;
                  const textBefore = val.slice(0, lastIndexOfFilter);
                  const textAfter = val.slice(
                    lastIndexOfFilter + colsPopup.filter.length + 1,
                  );
                  setVal(`${textBefore} /${colName} ${textAfter}`);

                  textRef?.current?.focus();
                  setColsPopup({ hidden: true, filter: "", line: 0 });
                }}
                className="flex w-full min-w-max items-center gap-2 rounded   px-2.5 py-2  transition-all duration-300 hover:bg-grey-600"
              >
                {getColumnDisplayName(c).split(" ").join("_").toLowerCase()}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
