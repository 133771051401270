import { useState, useEffect } from "react";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import firebase_app from "../firebase/config";
import { UserCredits } from "../models/userCredits";

const useUserCredits = (userId: string | null) => {
  const [loading, setLoading] = useState(true);
  const [userCredits, setUserCredits] = useState<UserCredits>();

  useEffect(() => {
    if (!userId) {
      return;
    }
    const userRef = doc(getFirestore(firebase_app), `users`, userId);
    getDoc(userRef).then((userSnap) => {
      if (userSnap.exists()) {
        setLoading(false);
        setUserCredits(userSnap.data() as UserCredits);
      }
    });
  }, [userId]);

  return { loading, userCredits };
};

export default useUserCredits;
