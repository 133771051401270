import { Link } from "react-router-dom";
import Header from "../../components/Forms/Header";
import Login from "../../components/Forms/Login";
import { LoginAside } from "./LoginAside";


export default function LoginPage() {

  return (
    <>
      <div className="flex h-full min-h-screen w-full bg-white">
        <div className="h-full  flex-grow space-y-4  px-4 pt-4 sm:px-6 lg:px-20">
          <Header isLogin={true} />
          <Login />
          <div className="flex flex-col gap-y-1 mt-3 text-center text-sm text-grey-100">
            <p>
              Don't have an account yet?
              <Link
                to={"/signup"}
                className="pl-1 font-medium text-primary hover:text-primaryhover"
              >
                Signup
              </Link>
            </p>
            <p>
              Don't remember the password?
              <Link
                to={"/forgotPassword"}
                className="pl-1 font-medium text-primary hover:text-primaryhover"
              >
                Forgot password
              </Link>
            </p>
          </div>
        </div>
        <LoginAside />
      </div>
    </>
  );
}
