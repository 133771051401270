import { twMerge } from "tailwind-merge";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  idFor: string;
  labelText: string;
  overrideClasses?: string;
}
export const FloatingLabelInput = ({
  idFor,
  labelText,
  overrideClasses,
  ...props
}: Props) => {
  return (
    <div className="relative">
      <input
        id={idFor}
        autoFocus={true}
        type="text"
        className={twMerge(
          "peer block w-full rounded-lg  border border-primary bg-transparent px-2.5 pb-2.5 pt-4 text-xs  text-black focus:border-primary focus:outline-none focus:ring-0",
          overrideClasses,
        )}
        placeholder=" "
        {...props}
      />
      <label
        htmlFor={idFor}
        className="rtl:peer-focus:translate-x-/4  absolute  start-1 top-2  z-10 origin-[0] -translate-y-4 translate-x-3 scale-75 transform rounded-md bg-white  px-2 text-xs text-grey-200 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:translate-x-0 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:translate-x-3 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-black rtl:peer-focus:left-auto"
      >
        {labelText}
      </label>
    </div>
  );
};
