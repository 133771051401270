import { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  getFirestore,
  where,
  orderBy,
} from "firebase/firestore";
import firebase_app from "../firebase/config";
import { UserChat } from "../models/data";

const useAllChats = (userId: string | null) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<UserChat[]>([]);

  useEffect(() => {
    if (!userId) {
      return;
    }
    // Create a reference to the Firestore collection
    const chatsQuery = query(
      collection(getFirestore(firebase_app), `/chats`),
      where("participants", "array-contains", userId),
      where("isDeleted", "==", false),
      orderBy("isDeleted"),
      orderBy("timestamp", "desc"),
    );
    // Set up a snapshot listener to listen for changes
    const unsubscribe = onSnapshot(
      chatsQuery,
      (snapshot) => {
        // console.log("chats table had an update.");
        const chats: UserChat[] = [];
        snapshot.forEach((chat) => {
          const chatData = chat.data();
          const chat_id = chat.id;
          chats.push(
            new UserChat({
              chat_id,
              timestamp: chatData.timestamp,
              chat_name: chatData.chat_name,
              version: chatData.version,
              table_id: chatData.table_id,
              progress: chatData.progress || 0,
              isDeleted: chatData.isDeleted,
            }),
          );
        });
        setLoading(false);
        setData(chats);
      },
      (error) => {
        console.log("ERROR OCCURRED");
        setLoading(false);
        console.error(error);
      },
    );

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [userId]);

  return { loading, data };
};

export default useAllChats;
