import * as React from "react";
const ToolbarToggleBackground = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={136}
    height={40}
    viewBox="0 0 136 40"
    fill="none"
    {...props}
  >
    <path
      id="Rectangle 63"
      d="M0 6C0 2.68629 2.68629 0 6 0H121.535C124.848 0 127.535 2.68629 127.535 6V7.61067C127.535 9.14497 128.122 10.621 129.177 11.7353L134.936 17.8198C136.076 19.0236 136.023 20.9235 134.818 22.0622L129.413 27.1718C128.214 28.3052 127.535 29.8822 127.535 31.5321V34C127.535 37.3137 124.848 40 121.535 40H5.99999C2.68628 40 0 37.3137 0 34V6Z"
      fill="#111010"
    />
  </svg>
);
export default ToolbarToggleBackground;
