import { Company } from "../../models/data";
import { useState } from "react";
import {  normalizeCompanies } from "../../components/utils";
import CheckCircleIcon from "@heroicons/react/24/outline/CheckCircleIcon";
import { CompanyListTable } from "../../components/table/CompanyListTable";

import { toast } from "react-toastify";
import Papa from "papaparse";

export function UploadTier({
  setUserUploadedJson,
}: {
  setUserUploadedJson: React.Dispatch<
    React.SetStateAction<Record<string, string>[] | null>
  >;
}) {
  const [normalizedResults, setNormalizedResults] = useState<{
    companies: Company[];
  } | null>(null);

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const id = toast.loading("File uploading");
    const file = e.target.files != null ? e.target.files[0] : null;
    if (file) {
      const rawCompanies: Array<Record<string, string>> = [];
      Papa.parse(file, {
        worker: true,
        header: true,
        step: function (row) {
          // console.log("Row:", row.data);
          rawCompanies.push(row.data as Record<string, string>);
        },
        complete: function () {
          console.log("All done!");
          toast.update(id, {
            render: "File upload successfully",
            type: "success",
            isLoading: false,
            autoClose: 5000,
          });

          setUserUploadedJson(rawCompanies);
          setNormalizedResults({
            companies: normalizeCompanies(rawCompanies.slice(0, 100)),
          });
        },
      });
    }
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onload = (event) => {
    //     const csvText = event.target?.result as string;
    //     try {
    //       const rawCsvToJson: Record<string, string>[] = csvParser(csvText, {
    //         columns: true,
    //         skip_empty_lines: true,
    //         skip_records_with_error: true,
    //       });
    //       if (rawCsvToJson.length > 10000) {
    //         alert(
    //           "CSV File is too big to be processed. It can not have more than 10000 companies. Please try again.",
    //         );
    //         return;
    //       }
    //       // set chat type's list to rawCsvToJson so it can be sent to the LLM
    //       const companies = normalizeCompanies(rawCsvToJson);
    //       setUserUploadedJson(rawCsvToJson);
    //       const columns = getUniqueColumns(companies);
    //       setNormalizedResults({ columns, companies });
    //     } catch (err) {
    //       toast.error(
    //         "We are unable to process this file. Please refer to help section.",
    //       );
    //     }
    //   };
    //   reader.readAsText(file);
    //   toast.update(id, { render: "File upload successfully", type: "success", isLoading: false, autoClose: 5000 });
    // } else {
    //   setNormalizedResults(null);
    //   toast.update(id, { render: "File upload unsuccessfully", type: "error", isLoading: false, autoClose: 5000 });
    // }
  }

  return (
    <div className="mt-auto flex h-full w-full flex-col  gap-5">
      <div className="mt-auto flex items-center justify-between gap-4">
        <p className="text-left">
          Please upload the csv file containing your initial list.
        </p>
      </div>

      <div className="upload-field">
        <input
          type="file"
          name="uploadcsv"
          id="uploadcsv"
          accept=".csv"
          onChange={(e) => {
            handleFileChange(e);
          }}
        />
      </div>
      {normalizedResults && (
        <div className="flex h-full flex-1 flex-grow flex-col justify-between">
          <div className="h-max max-h-[90vh] flex-1 flex-grow overflow-y-auto">
            <div className="mb-2 flex items-start justify-start gap-2">
              <CheckCircleIcon width={20} />
              <p>List attached. (Showing first 100)</p>
            </div>
            <div className="flex-grow overflow-hidden">
              <CompanyListTable
                companies={normalizedResults.companies}
                startIndex={1}
                height="small"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
