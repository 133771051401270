import * as React from "react";
const ElectricityIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.4847 11.8247C20.4563 11.7045 20.3987 11.5931 20.3169 11.5006C20.2351 11.408 20.1318 11.3371 20.016 11.2941L14.6151 9.26812L15.9894 2.39343C16.0205 2.2337 15.9989 2.06818 15.9277 1.92185C15.8565 1.77552 15.7396 1.65632 15.5947 1.58223C15.4498 1.50815 15.2848 1.4832 15.1244 1.51115C14.9641 1.5391 14.8173 1.61843 14.706 1.73718L4.20599 12.9872C4.12071 13.077 4.05902 13.1866 4.02642 13.3061C3.99383 13.4257 3.99135 13.5514 4.01921 13.6721C4.04706 13.7928 4.10439 13.9048 4.18606 13.9979C4.26773 14.0911 4.37121 14.1625 4.48724 14.2059L9.89005 16.2319L8.51943 23.0991C8.4883 23.2588 8.50998 23.4243 8.58118 23.5706C8.65238 23.717 8.76925 23.8362 8.91414 23.9103C9.05903 23.9843 9.22409 24.0093 9.3844 23.9813C9.54472 23.9534 9.69159 23.8741 9.80286 23.7553L20.3029 12.5053C20.3866 12.4154 20.447 12.3064 20.4787 12.1877C20.5104 12.069 20.5125 11.9444 20.4847 11.8247ZM10.5079 20.8087L11.4894 15.8981C11.5246 15.724 11.4967 15.543 11.4109 15.3874C11.325 15.2319 11.1868 15.1119 11.0207 15.0487L6.06693 13.1878L14.0001 4.68843L13.0194 9.59906C12.9843 9.77322 13.0121 9.95419 13.098 10.1097C13.1838 10.2653 13.3221 10.3853 13.4882 10.4484L18.4382 12.3047L10.5079 20.8087Z"
        fill="white"
      />
    </svg>
  );
};
export default ElectricityIcon;
