import { useCallback, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../pages/sidebar/Sidebar";
import Navbar from "./NavBar";
import { twMerge } from "tailwind-merge";
import FullScreenLottie from "src/lotties/FullScreenLottie";
import { useStateContext } from "src/context/StateContext";
import { useTutorialMode } from "src/hooks/useTutorialMode";
import useChatUpdates from "src/hooks/useChatUpdates";
import { ChatUpdate, CreateAndSendMessage, Message } from "src/models/data";
import backend_service from "../services/backend_service";
import { FiltersLibrary } from "src/pages/filters/FiltersLibrary";
import { DataPointExplorer } from "src/pages/dataPointExplorer/DataPointExplorer";
import InputContainer, { InputWithSuggestion } from "./elements/InputContainer";
import Modal from "./elements/Modal";
import Spinner, { SimpleSpinner } from "./elements/Spinner";
import ChatMessage from "src/pages/chat/ChatMessage";
import RequestStatus from "src/pages/RequestUpdates/RequestStatus";
import ContactForm from "./ContactForm";
import useChatDetails from "src/hooks/useChatDetails";
import { ChatStarText } from "src/pages/toolbar/chatToolbarElements";

export default function OnboardingHome() {
  const { appInitialized, user } = useAuth();

  const navigate = useNavigate();
  useEffect(() => {
    if (!appInitialized) {
      return;
    }
    if (!user) {
      navigate("/login");
    }
    if (!user?.emailVerified) {
      navigate("/verify");
    }
  }, [user, appInitialized, navigate]);

  if (!appInitialized) {
    return <FullScreenLottie />;
    // return <Spinner text="Kuration.io is loading..." />;
  }
  return (
    <div className="relative flex max-h-screen w-full flex-col overflow-hidden bg-white">
      <div className={twMerge("sticky top-0 z-40 w-full  bg-dark md:hidden")}>
        <Navbar />
      </div>
      <div className="flex w-full flex-grow">
        <div className=" bg-bg-1 ">
          <Sidebar />
        </div>

        <div
          className={` flex h-[calc(100vh_-_78px)] flex-1 flex-grow bg-bg-2 pr-0 pt-4 transition-all duration-300  ease-out md:h-screen md:p-9 md:pl-5 md:pr-0 `}
        >
          <div className="max-h-full w-full">
            <OnboardingChatContainer />
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

const OnboardingChatContainer = () => {
  const [socket, setSocket] = useState<WebSocket | null>();
  const { filtersOpen, dpeState } = useStateContext();
  const [functionModal, setFunctionModal] = useState({
    isOpen: false,
    objective: "",
    requirements: [""],
  });
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);
  const [currentOpenChat, setCurrentOpenChat] = useState<string | undefined>();
  const { tutorialOpen } = useTutorialMode();

  const chatUpdates = useChatUpdates();
  const [messages, setMessages] = useState<Message[]>([]);

  const [isMessageStreaming, setIsMessageStreaming] = useState(false);
  const messagesContainer = useRef(null);

  const { user } = useAuth();
  const navigator = useNavigate();
  const [chatIsLoading] = useState(false);

  const [inputContainerHeight, setInputContainerHeight] = useState<number>(87);
  const inputContainertRef = useCallback(
    (node: HTMLDivElement) => {
      if (!node) return;
      const resizeObserver = new ResizeObserver(() => {
        let newHeight = node.getBoundingClientRect().height;
        if (newHeight !== inputContainerHeight) {
          setInputContainerHeight(newHeight);
        }
      });
      resizeObserver.observe(node);
    },
    [inputContainerHeight],
  );
  const heightVariants: { [key: number]: string } = {
    87: "h-[calc(100vh-87px)]",
    111: "h-[calc(100vh-111px)]",
    135: "h-[calc(100vh-135px)]",
    159: "h-[calc(100vh-159px)]",
    183: "h-[calc(100vh-183px)]",
    207: "h-[calc(100vh-207px)]",
    231: "h-[calc(100vh-231px)]",
    255: "h-[calc(100vh-255px)]",
    279: "h-[calc(100vh-279px)]",
    303: "h-[calc(100vh-303px)]",
  };

  const useCasePopupRef = useRef();
  const openUseCasePopup = () => (useCasePopupRef.current as any)?.open();

  const handleSocketMessageEventCallback = useCallback(
    function handleSocketMessageEvent(ev: MessageEvent) {
      let myStreamStringChunk = ev.data as string;
      if (
        myStreamStringChunk.startsWith("##OPENEND##ERROR_RECHARGE_REQUIRED:")
      ) {
        toast("You ran out of credits. Please recharge first and come back.");
        setIsMessageStreaming(false);
        navigator("/profile");
      } else if (myStreamStringChunk.startsWith("##OPENEND##ERROR_TOKEN:")) {
        user?.getIdToken(true);
        toast("Login expired. Please login again");
        setIsMessageStreaming(false);
      } else if (myStreamStringChunk.startsWith("##OPENEND##ERROR:")) {
        myStreamStringChunk = myStreamStringChunk.replace(
          "##OPENEND##ERROR:",
          "",
        );
        toast.error(`An error occurred: ${myStreamStringChunk}`);
        setIsMessageStreaming(false);
        alert(`An error occurred ${myStreamStringChunk}`);
      } else if (myStreamStringChunk.startsWith("##OPENEND##FUNC:")) {
        let func_call = myStreamStringChunk.replace("##OPENEND##FUNC:", "");
        const func_call_json = JSON.parse(func_call);
        setFunctionModal({
          isOpen: true,
          objective: func_call_json["function_call"]["arguments"]["objective"],
          requirements:
            func_call_json["function_call"]["arguments"]["requirements"],
        });
        setIsMessageStreaming(false);
      } else if (myStreamStringChunk.startsWith("##OPENEND##FORM_SUCESSFUL")) {
        toast.success("All done 🥳");
        setIsMessageStreaming(false);
      } else if (myStreamStringChunk.startsWith("##OPENEND##USECASEWRONG")) {
        setIsMessageStreaming(false);
        // window.open("https://kurationai.typeform.com/notdoable", "_blank");
        openUseCasePopup();
      } else if (myStreamStringChunk.startsWith("##OPENEND##")) {
        let open_most_recent_by_id = myStreamStringChunk.split("_")[1];
        if (open_most_recent_by_id && open_most_recent_by_id !== "LLM") {
          setCurrentOpenChat(open_most_recent_by_id);
        }
        setIsMessageStreaming(false);
      } else {
        setMessages((prev) => {
          let newMessages = [...prev];
          let lastMess = newMessages[newMessages.length - 1];
          newMessages[newMessages.length - 1] = {
            ...lastMess,
            content: lastMess?.content + myStreamStringChunk,
          };
          return newMessages;
        });
      }
    },
    [navigator, user],
  );

  useEffect(() => {
    if (socket && socket.readyState === socket.OPEN) {
      return;
    }
    let ws: WebSocket;
    try {
      // setConnectionState("connecting");

      ws = new WebSocket(backend_service.ws_url);

      if (ws) {
        ws.onmessage = (ev: MessageEvent) => {
          handleSocketMessageEventCallback(ev);
        };
        ws.onerror = (ev: Event) => {
          console.log(`socket error ${ev}`, ev);
          setSocket(null);
        };
        ws.onclose = (ev: CloseEvent) => {
          console.log(`socket error ${ev}`, ev);
          setSocket(null);
        };
        ws.onopen = (ev: Event) => {
          setSocket(ws);
        };
      }
    } catch (error) {
      console.log(`socket error ${error}`, error);
      alert(`socket error ${error}`);
    }
    return () => {};
  }, [handleSocketMessageEventCallback, socket]);

  const addUserMessage = (
    userMess: string,
    userUploadedJson?: Record<string, string>[] | null,
  ) => {
    if (isMessageStreaming) {
      console.log("A message is already in progress.");
      return;
    }
    const listMess: Message = {
      role: "logging",
      type: "user_list",
      data: userUploadedJson ? userUploadedJson : [],
      time: new Date().toLocaleTimeString(),
      content: null,
    };
    setMessages((prev) => {
      return [
        ...prev,
        ...(userUploadedJson ? [listMess] : []),
        {
          content: userMess,
          role: "user",
          time: new Date().toLocaleTimeString(),
        },
        {
          content: "",
          role: "assistant",
          time: new Date().toLocaleTimeString(),
        },
      ];
    });
  };
  const createAndSendMessage: CreateAndSendMessage = async (
    messageText: string,
    userUploadedJson?: Record<string, string>[] | null,
    hiddenMessage: boolean = false,
    type: "contact_form" | "manual_search" | "prompt" = "prompt",
    visualText?: string,
  ) => {
    let newChatType = "onboarding";
    if (userUploadedJson) {
      newChatType = "upload";
    }
    if (!hiddenMessage) addUserMessage(messageText, userUploadedJson);
    if (hiddenMessage && visualText && visualText?.length > 0) {
      addUserMessage(visualText, userUploadedJson);
    }
    if (type === "contact_form" || type === "manual_search") {
      //for contact form, the messageText would actual be stringified json,
      // so we dent wanna restringing it so we first parse it
      messageText = JSON.parse(messageText);
    }
    let data_to_send = JSON.stringify({
      type: type,
      data: messageText,
      chat_id: currentOpenChat,
      chat_type: newChatType,
      ...(userUploadedJson ? { uploaded_list: userUploadedJson } : {}),
      idToken: await user?.getIdToken(),
    });

    try {
      sendMessage(data_to_send);
    } catch (error) {
      console.log(`socket error ${error}`, error);
      alert(`socket error ${error}`);
    }
    // if (!hiddenMessage) createStreamingMessage();
  };
  function sendMessage(text: string) {
    try {
      if (socket && socket.readyState === socket.OPEN) {
        socket?.send(text);
        setIsMessageStreaming(true);
        console.log("MESSAGE IS NOW STREAMING");
      } else {
        toast.error("Problem connecting to server. Refresh page.");
      }
    } catch (error) {
      console.log(`socket error ${error}`, error);
      // alert(`socket error ${error}`);
    }
  }

  return (
    <div className="relative flex h-full flex-grow justify-center">
      {((!chatIsLoading && messages.length === 0) || tutorialOpen) && (
        <div className="flex h-[90vh] flex-col justify-between px-5">
          <div className="flex-grow">
            <ChatStarText />
          </div>
          <div className="mb-2 flex justify-center">
            <InputWithSuggestion
              addUserMessage={(text) => {
                createAndSendMessage(text);
              }}
              isMessageAllowed={true}
              placeholder="Ask anything—your imagination is the only limit!"
            />
          </div>
        </div>
      )}
      {filtersOpen && currentOpenChat && !tutorialOpen && (
        <div className="h-full ">
          <FiltersLibrary />
        </div>
      )}
      {dpeState.isOpen && currentOpenChat && !tutorialOpen && (
        <div className="absolute inset-0 h-full bg-white">
          <DataPointExplorer dpeState={dpeState} />
        </div>
      )}
      {!filtersOpen && !dpeState.isOpen && (
        <div
          className={`flex h-[90vh] flex-col justify-between ${messages.length > 0 ? "w-3/5" : ""} ${tutorialOpen ? "hidden md:block" : ""}`}
        >
          {chatUpdates.length > 0 && (
            <ChatStatusIndictor
              latestChatUpdate={chatUpdates[chatUpdates.length - 1]}
            />
          )}
          {chatIsLoading && (
            <Spinner
              text=""
              onWhiteBackground
              // styleClasses={[`${sidebarOpen ? "md:ml-[300px]" : ""}`]}
            />
          )}
          {messages.length > 0 && (
            <div
              className={`flex flex-grow flex-col overflow-auto overflow-y-auto transition-transform duration-300 ease-out ${heightVariants[inputContainerHeight]} p-3 pt-0 md:px-6`}
            >
              {messages.map((mess, index) => (
                <ChatMessage
                  key={index.toString() + mess.time}
                  message={mess}
                  userName={
                    mess.role === "user" || mess.type === "user_list"
                      ? `${user?.displayName ?? ""}`
                      : "Kuration AI"
                  }
                  isLast={messages.length - 1 === index}
                />
              ))}
              {/* Add more messages */}
              {!chatIsLoading && chatUpdates?.length > 0 && (
                <RequestStatus chatUpdates={chatUpdates} />
              )}
            </div>
          )}
          <div ref={messagesContainer} className="invisible h-0 w-0"></div>
          {chatUpdates?.length < 1 && messages.length > 0 && (
            <div ref={inputContainertRef} className="p-5 md:p-6">
              <InputContainer
                isMessageAllowed={
                  !!socket &&
                  socket.readyState === socket.OPEN &&
                  !isMessageStreaming
                }
                addUserMessage={createAndSendMessage}
              />
            </div>
          )}
          {functionModal.isOpen && (
            <Modal
              onClose={() => {
                setFunctionModal({
                  isOpen: false,
                  objective: "",
                  requirements: [],
                });
              }}
              isOpen={functionModal.isOpen}
              header={"Requirements Complete. Launch or update?"}
              body={
                <div>
                  <h2 className="text-xl">Objective</h2>
                  <p>{functionModal.objective}</p>
                  <h3 className="text-lg">Requirements</h3>
                  <ul>
                    {functionModal.requirements.map((req) => (
                      <li key={req}>{req}</li>
                    ))}
                  </ul>
                </div>
              }
              actions={[
                <button
                  key={"UpdateReq"}
                  className="rounded-lg border-2 border-white p-2 px-4 text-white"
                  onClick={() => {
                    console.log("SOCKET EXITS?: ", socket);
                    createAndSendMessage("I want to update requirements.");
                    setFunctionModal({
                      isOpen: false,
                      objective: "",
                      requirements: [],
                    });
                  }}
                >
                  Update Requirements
                </button>,
                <button
                  className="rounded-lg bg-white p-2 px-4 text-dark"
                  key={"Launch"}
                  onClick={() => {
                    setIsFormModalOpen(true);
                  }}
                >
                  Launch
                </button>,
              ]}
            />
          )}
          {isFormModalOpen && (
            <Modal
              isOpen={isFormModalOpen}
              header={"Our bots are working to complete your results."}
              body={
                <ContactForm
                  onLaunch={() => {
                    setIsFormModalOpen(false);
                    setFunctionModal({
                      isOpen: false,
                      objective: "",
                      requirements: [],
                    });
                  }}
                  createAndSendMessage={createAndSendMessage}
                />
              }
              actions={[]}
              onClose={() => {
                setIsFormModalOpen(false);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

function ChatStatusIndictor({
  latestChatUpdate,
}: {
  latestChatUpdate: ChatUpdate;
}) {
  const { chatId } = useParams();
  const { chatDetails } = useChatDetails(chatId || null);
  if (latestChatUpdate.type === "final_list_complete") {
    return <></>;
  }
  return (
    <div
      className={` fixed right-0 z-40 flex  w-full items-center justify-center bg-zblue py-2 font-mono text-xs text-white shadow-md md:w-[560px] md:text-base`}
    >
      {latestChatUpdate.type === "initial_list_started" && (
        <div className="flex items-center justify-center gap-2">
          <SimpleSpinner />
          <span>Bots are gathering companies from knowledge base</span>
        </div>
      )}
      {latestChatUpdate.type === "initial_list_complete" && (
        <div className="flex items-center justify-center gap-2">
          <SimpleSpinner />
          <span>
            Bots are researching companies
            {chatDetails && (
              <span className="font-extrabold"> {chatDetails.progress}% </span>
            )}
          </span>
        </div>
      )}
    </div>
  );
}
