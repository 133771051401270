import * as React from "react";
const ToolbarButton = (props: React.SVGProps<SVGSVGElement>) => {
  const classes = props.className;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="32"
      fill="none"
      viewBox="0 0 6 32"
      {...props}
    >
      <rect
        width="6"
        height="32"
        fill="#D1D1D1"
        className={classes}
        rx="3"
      ></rect>
    </svg>
  );
};
export default ToolbarButton;
