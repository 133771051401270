import { RocketIcon } from "../svgs/RocketIcon";

export default function FormAction({
  type = "Button",
  action = "submit",
  text,
}: {
  action: "button" | "submit" | "reset" | undefined;
  type: string;
  text: string;
}) {
  return (
    <div className="flex justify-center">
      {type === "Button" ? (
        <button
          type={action}
          formAction={action}
          className="group relative flex  items-center justify-center gap-2 rounded-md border border-transparent bg-primary px-6 py-3 text-lg font-medium text-white hover:bg-primaryhover focus:outline-none focus:ring-2 focus:ring-primaryhover focus:ring-offset-2"
        >
          <RocketIcon className={"h-[18px] w-[18px]"} />
          {text}
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}
