import { FormSelect } from "src/pages/filters/FormSelect";
import { twMerge } from "tailwind-merge";
import { IconButton } from "../elements/IconButton";
import CloseIcon from "../svgs/CloseIcon";

export interface FilterFormField {
  id: string;
  key: string;
  type:
    | ""
    | "notEquals"
    | "equals"
    | "startsWith"
    | "exists"
    | "contains"
    | "greaterThan"
    | "lessThan";
  value: string | number | boolean;
}

export function getUpdatedForm({
  prev,
  updatedField,
  k,
  v,
}: {
  prev: FilterFormField[];
  updatedField: FilterFormField;

  k: keyof FilterFormField;
  v: FilterFormField["key"];
}) {
  const copy = [...prev];

  const target = copy.find((ff) => ff.id === updatedField.id);
  if (target) {
    target[k] = v as any;
  }
  return copy;
}

export function FilterFields({
  filterForm,
  handleFormUpdate,

  cols,
  handleFilterRemove,
  filterOptions,
}: {
  filterForm: FilterFormField[];
  cols: Array<{ id: string; name: string }>;

  handleFilterRemove(f: FilterFormField): void;
  handleFormUpdate(
    f: FilterFormField,
    k: keyof FilterFormField,
    v: FilterFormField["key"],
  ): void;
  filterOptions: {
    id: FilterFormField["type"];
    name: string;
  }[];
}) {
  return (
    <div className="my-3 flex flex-col gap-8">
      {filterForm.length < 1 && <p>No filters added yet.</p>}
      {filterForm.map((f) => (
        <div
          key={f.id}
          id={f.id}
          className="flex flex-wrap justify-start gap-2"
        >
          <FormSelect
            overrideClasses="w-min"
            options={cols.map((i) => ({ id: i.id, name: i.name }))}
            setVal={(v) => {
              handleFormUpdate(f, "key", v);
            }}
            val={f.key}
          />
          {f.key && (
            <FormSelect
              overrideClasses="w-min"
              options={filterOptions}
              val={f.type}
              setVal={(v) => {
                handleFormUpdate(f, "type", v);
              }}
            />
          )}
          {getCorrectField(f, { handleFormUpdate })}
          <IconButton
            icon={<CloseIcon />}
            overrideClasses="ml-auto h-[42px] w-[42px] max-w-min  gap-0 border-none p-1.5"
            variant="outline"
            text={<></>}
            onClick={() => {
              handleFilterRemove(f);
            }}
          />
        </div>
      ))}
    </div>
  );
}

function getCorrectField(
  f: FilterFormField,
  {
    handleFormUpdate,
  }: {
    handleFormUpdate(
      f: FilterFormField,
      k: keyof FilterFormField,
      v: FilterFormField["key"],
    ): void;
  },
) {
  if (f.type === "exists") {
    return (
      <FormSelect
        overrideClasses="w-min"
        options={[
          { id: "true", name: "True" },
          { id: "false", name: "False" },
        ]}
        val={f.value as string}
        setVal={(v) => {
          handleFormUpdate(f, "value", v);
        }}
      />
    );
  }
  if (
    f.type === "equals" ||
    f.type === "contains" ||
    f.type === "notEquals" ||
    f.type === "startsWith" ||
    f.type === "greaterThan" ||
    f.type === "lessThan"
  )
    return (
      <input
        id="default"
        type={
          f.type === "greaterThan" || f.type === "lessThan"
            ? "number"
            : undefined
        }
        value={f.value as string}
        className={twMerge(
          "h-10 max-w-min flex-1 rounded-md border border-neutral-200 px-2.5 py-3 text-sm leading-none outline-none    focus-within:border-purple hover:border-purple",
        )}
        placeholder="e.g. Not found or x"
        onChange={(e) => {
          handleFormUpdate(f, "value", e.target.value);
        }}
      />
    );
}
