import { useAuth } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import { RocketIcon } from "src/components/svgs/RocketIcon";
import { cancelSubscription, getSubscription } from "src/components/utils";
import { toast } from "react-toastify";
import Spinner from "src/components/elements/Spinner";

export default function PaymentPage() {
  const { user } = useAuth();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const subscription = await getSubscription(user?.uid ?? "");
        setId(subscription.id);
        setName(subscription.name);
        setDescription(subscription.description!);
        setLoading(false);
      } catch (error) {
        // console.log(`Error fetching subscription: ${error}`);
        toast.error(`Error fetching subscription: ${error}`);
      }
    };
    fetchSubscription();
  }, [user?.uid]);

  async function handleUnscription() {
    await cancelSubscription(id);
    toast.success("Cancel Successfully");
  }

  return (
    <div className="flex flex-col bg-white gap-y-[48px]">
      <div className="flex flex-col gap-y-2">
        <div className="text-5xl">Payment</div>
        <div className="text-2xl text-grey-100">Manage your subscriptions</div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col">
          <div className="text-2xl">Current Subscription</div>
          <hr className="pb-6" />
          <div className="grid bg-grey-600 rounded-md my-14 relative">
            {loading && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-50">
                <Spinner text="Loading" onWhiteBackground />
              </div>
            )}
            <div className="grid p-6 gap-y-4 justify-center md:flex md:justify-between md:w-[534px]">
              <div className="flex text-2xl items-center justify-center gap-2">
                <RocketIcon />
                <div>{name}</div>
              </div>
              <button
                className={`flex h-12 items-center justify-center gap-1.5 rounded-md px-4 text-base ${id === "" ? "invisible" : ""} text-error leading-[19.2px] border border-error bg-lightpurple hover:bg-primaryhover`}
                onClick={handleUnscription}
              >
                <div>Cancel subscription</div>
              </button>
            </div>
            <div className="text-base p-6">
              <div>{description}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

