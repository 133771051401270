import React, { ReactNode } from "react";

const Modal = ({
  isOpen,
  onClose,
  header,
  body,
  actions,
}: {
  isOpen: boolean;
  onClose: () => void;
  header: ReactNode;
  body: ReactNode;
  actions: ReactNode[];
}) => {
  const closeModal = () => {
    onClose();
  };

  return (
    <div
      className={`fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center ${isOpen ? "visible" : "invisible"
        }`}
    >
      <div
        className="fixed left-0 top-0 h-full w-full bg-black bg-opacity-50 backdrop-blur-md transition-opacity"
        onClick={closeModal}
      ></div>
      <div className="z-20 w-full max-w-[90vw] max-h-[80vh] overflow-auto md:max-w-2xl rounded-lg bg-dark p-4 shadow-md">
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-lg text-white">{header}</h2>
          <button
            title="close modal"
            onClick={closeModal}
            className="text-grey-300 hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="text-white">{body}</div>
        <div className="mt-4 flex justify-end gap-4">{actions}</div>
      </div>
    </div>
  );
};

export default Modal;
