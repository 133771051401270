import { UseQueryResult } from "@tanstack/react-query";
import { SimpleSpinner } from "../elements/Spinner";
import { PreCuratedListItem } from "src/models/preCuratedList";
import backend_services from "src/services/backend_service";
import { useAuth } from "src/context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton } from "../elements/IconButton";
import ElectricityIcon from "../svgs/Electricity";
import { useListFilterQuery } from "src/hooks/useSearchQuery";

export function ListDetail({
  detailsQuery,
  setChatCreationLoading,
}: {
  detailsQuery: UseQueryResult<PreCuratedListItem | null, unknown>;
  setChatCreationLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { user } = useAuth();
  const { listId } = useParams();
  const navigator = useNavigate();
  const listFilter = useListFilterQuery();
  async function createChatFromList() {
    if (!user) {
      return;
    }
    setChatCreationLoading(true);
    const result = await backend_services
      .fetchProtectedData(
        `/chat/from_pre_curated_list/${listId}`,
        user?.getIdToken(),
        undefined,
        "POST",
        { filters: listFilter },
      )
      .then((res) => {
        setChatCreationLoading(false);

        return res.json();
      })
      .catch((err) => {
        setChatCreationLoading(false);

        console.log(err);
      });
    if (
      result.data &&
      result.data.chat_id &&
      typeof result.data.chat_id === "string"
    ) {
      navigator(`/chat/${result.data.chat_id}`);
    }
  }
  // console.log(detailsQuery.data);
  if (detailsQuery.isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <SimpleSpinner radius={20} overrideClasses="border-black" />
      </div>
    );
  }
  if (detailsQuery.error) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <button
          onClick={() => {
            detailsQuery.refetch();
          }}
        >
          Error fetching companies. <strong>Retry?</strong>
        </button>
      </div>
    );
  }

  return (
    <div className="flex justify-between pb-8">
      <p className="text-4xl font-normal text-neutral-900">
        {detailsQuery.data?.name}
      </p>
      <div>
        <IconButton
          icon={<ElectricityIcon />}
          text={"Enrich Results in chat"}
          onClick={() => {
            createChatFromList();
          }}
          variant="fill"
        />
      </div>
    </div>
  );
}
