import { twMerge } from "tailwind-merge";

export default function Spinner({
  text,
  onWhiteBackground = false,
  styleClasses,
}: {
  text: string;
  styleClasses?: string[];
  onWhiteBackground?: boolean;
}) {
  return (
    <div
      className={`absolute inset-0 z-10 flex flex-col items-center justify-center gap-3 ${styleClasses?.join(
        " ",
      )}`}
    >
      <div
        className={`inline-block ${onWhiteBackground ? "border-dark bg-white" : "border-white bg-dark"
          }  h-3/5 max-h-[2rem] w-3/5 max-w-[2rem]  animate-spin rounded-full border-4 border-solid  border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
      {text && (
        <p className={`${onWhiteBackground ? "text-dark" : "text-white"}`}>
          {text}
        </p>
      )}
    </div>
  );
}

export function SimpleSpinner({ radius = 10, overrideClasses = "" }: { radius?: number, overrideClasses?: string }) {
  return (
    <div
      className={twMerge(`animate-spin rounded-full border-2 border-solid   align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`, overrideClasses, "border-r-transparent")}
      style={{ height: radius + "px", width: radius + "px" }}
      role="status"
    ></div>
  );
}
