import StarsIcon from "src/components/svgs/Stars";
import { twMerge } from "tailwind-merge";

export const FilterTags = ({
  text,
  onClick,
  selected = false,
  withStars = false,
}: {
  text: string;
  selected?: boolean;
  withStars?: boolean;
  onClick: () => void;
}) => {
  return (
    <button
      onClick={() => {
        onClick();
      }}
      className={twMerge(
        `group flex h-[40px] w-max items-center justify-center gap-2.5 rounded-md border border-purple px-3.5 py-3 text-sm transition-all  duration-300 md:text-base`,
        `${selected ? "bg-purple text-white " : " bg-white text-black hover:bg-lightpurple"}`,
      )}
    >
      {withStars && <StarsIcon fill={selected ? "white" : ""} />}
      <div className="leading-none">{text}</div>
    </button>
  );
};
