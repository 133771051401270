import { InitialListItem } from "src/models/InitialList";
import GoogleMapPin from "../svgs/GoogleMapPin";
import UploadIcon from "../svgs/Upload";
import HandIcon from "../svgs/Hand";

const items: InitialListItem[] = [
    {
      _id: "1",
      name: "googleMap",
      description: "Find Location Businesses using Google Maps",
      icon: <GoogleMapPin />,
    },
    {
      _id: "2",
      name: "salesNavSearch",
      description: "Search for Leads using LinkedIn Sales Navigator",
      icon: (
        <img
          src="https://uploads-ssl.webflow.com/6697d10a891d3b0b8f8a6628/6698c438c5e7f16548da414d_linkedin-sales-navigator.jpg"
          alt="LinkedIn"
          style={{ height: "50px", width: "50px" }}
        />
      ),
    },
    {
      _id: "3",
      name: "GoogleJob",
      description: "Find Jobs using Google Jobs",
      icon: (
        <img
          src="https://uploads-ssl.webflow.com/6697d10a891d3b0b8f8a6628/66a0a0b111d8e45ce2d6450e_Screenshot%202024-07-24%20at%2012.05.22%E2%80%AFPM.png"
          alt="GoogleJob"
          style={{ height: "50px", width: "50px" }}
        />
      ),
    },
    {
      _id: "4",
      name: "parentCompanyLookup",
      description: "Find Subsidiaries of Parent Companies",
      icon: (
        <img
          src="https://uploads-ssl.webflow.com/6697d10a891d3b0b8f8a6628/66ad1bd25b219b54cb0a03d3_branch.png"
          alt="Parent Company Lookup"
          style={{ height: "50px", width: "50px" }}
        />
      ),
    },
    {
      _id: "5",
      name: "companyDetailsLookup",
      description: "Find Details of Any Company",
      icon: (
        <img
          src="https://uploads-ssl.webflow.com/6697d10a891d3b0b8f8a6628/66adea1e935a44006d9a4819_insurance-company.png"
          alt="Company Details Lookup"
          style={{ height: "50px", width: "50px" }}
        />
      ),
    },
    {
      _id: "6",
      name: "csvFileUploader",
      description: "Upload CSV file",
      icon: <UploadIcon />,
    },
    {
      _id: "7",
      name: "startManually",
      description: "Start Manually",
      icon: <HandIcon />,
    }
  ];

export default items;
