import { CompanyListTable } from "src/components/table/CompanyListTable";
import TableHeader from "./TableHeader";
import TableOptions from "./TableOptions";
import { Company, ResearchPlanItem } from "src/models/data";
import Pagination from "../elements/Pagination";
import { SimpleSpinner } from "../elements/Spinner";
import { getUniqueColumns } from "../utils";
import CustomModal from "../elements/CustomModel";
import { useState } from "react";
import ColumnAdder from "../columnAdder/ColumnAdder";
import { Column, Table } from "src/hooks/useChatTable";
import { twMerge } from "tailwind-merge";
import {
  PauseCircleIcon,
  PlayCircleIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "src/context/AuthContext";
import { useParams } from "react-router-dom";
import backend_services from "src/services/backend_service";
import { AltColumn } from "./tableUtils";
import { RowsAdder } from "../rowsAdder/RowsAdder";

export default function TableView({
  table,
  companies,
  handleNextPage,
  handlePreviousPage,
  hasNextPage,
  rowCount,
  totalPages,
  companiesLoading,
  page,
  pageSize,
  downloadHandler,
  columnDetails,
  type,
  companyLoadingColumns,
  alternativeColumns = [],
  column_list,
  rowIds
}: {
  table?: Table;
  type: "search" | "default" | "final" | "noTable";
  companiesLoading: boolean;
  companies: Company[];
  rowCount: number;
  page: number;
  hasNextPage: boolean;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  totalPages: number;
  pageSize: number;
  downloadHandler: () => void;
  columnDetails?: Array<ResearchPlanItem>;
  column_list?: Array<Column>;
  companyLoadingColumns?: string[][];
  alternativeColumns?: AltColumn[];
  rowIds?: string[];
}) {
  const [addColumnModalOpen, setAddColumnModalOpen] = useState(false);
  const [addRowsModalOpen, setAddRowsModalOpen] = useState(false);

  if (type === "default") {
    // console.log(table);
  }
  return (
    <div className="grid h-full grid-rows-[min-content,1fr]">
      <div>
        {table &&
          (table.run_status === "paused" || table.run_status === "running") && (
            <PauseResumeButton run_status={table.run_status} />
          )}
      </div>
      <div className="grid max-h-full grid-rows-[min-content,1fr,60px] gap-4 overflow-auto">
        <div className="flex max-w-[95vw] flex-grow flex-row gap-6  overflow-x-auto md:max-w-full md:flex-col md:gap-7 ">
          {/* top row with heading and analytics */}
          <TableHeader showFiltersLibraryButton={type === "default"} />
          {/* second row with icon buttons */}
          <TableOptions
            type={type}
            column_list={column_list}
            uniqueCols={column_list?.length || 0}
            rowCount={rowCount}
            downloadHandler={downloadHandler}
          />
        </div>
        <div className="flex h-full flex-1 flex-grow overflow-auto">
          {companiesLoading ? (
            <div className="relative flex h-10 flex-grow items-center justify-center">
              <SimpleSpinner radius={20} overrideClasses="border-black" />{" "}
            </div>
          ) : (
            <CompanyListTable
              companies={companies}
              startIndex={(page - 1) * pageSize + 1}
              columnDetails={columnDetails}
              companyLoadingColumns={companyLoadingColumns}
              setAddColumnModalOpen={
                type === "default" ? setAddColumnModalOpen : undefined
              }
              setAddRowsModalOpen={setAddRowsModalOpen}
              columnList={column_list}
              alternativeColumns={alternativeColumns}
              rowIds={rowIds}
            />
          )}
        </div>
        <div className="flex justify-between items-center pb-4  md:p-2 md:pb-1">
          {/* {companies.length > 0 && (
            <IconButton
              overrideClasses="max-w-max"
              icon={<PlusIcon className="fill-purpleHover" />}
              text={"Add new companies"}
              variant="outline"
              onClick={() => {
                setAddRowsModalOpen(true);
              }}
            />
          )} */}
          <div className="ml-auto">
            <Pagination
              currentPage={page}
              hasNextPage={hasNextPage}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              totalPages={totalPages}
            />
          </div>
        </div>
        {addColumnModalOpen && (
          <CustomModal
            isOpen={true}
            onClose={() => {
              setAddColumnModalOpen(false);
            }}
          >
            <div className="z-50 flex h-full items-center justify-center">
              <ColumnAdder
                columns={table?.column_list || []}
                onClose={() => {
                  setAddColumnModalOpen(false);
                }}
                onSubmit={(tool) => {
                  setAddColumnModalOpen(false);
                }}
              />
            </div>
          </CustomModal>
        )}
        {addRowsModalOpen && (
          <CustomModal
            isOpen={true}
            onClose={() => {
              setAddRowsModalOpen(false);
            }}
          >
            <div className="z-50 flex h-full items-center justify-center">
              <RowsAdder  setAddRowsModalOpen={setAddRowsModalOpen}/>
            </div>
          </CustomModal>
        )}
      </div>
    </div>
  );
}

const PauseResumeButton = ({
  run_status,
}: {
  run_status: Table["run_status"];
}) => {
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const { user } = useAuth();
  const { chatId } = useParams();
  const isPaused = run_status === "paused";
  return (
    <button
      onClick={async () => {
        if (isRequestLoading || !user) {
          return;
        }
        setIsRequestLoading(true);
        await backend_services
          .fetchProtectedData(
            `/chat/${chatId}/${isPaused ? "resume_final_research" : "pause_final_research"}`,
            user?.getIdToken(),
            undefined,
            "POST",
          )
          .then((res) => {
            if (res.status !== 200) {
              throw new Error("invalid result");
            }

            setIsRequestLoading(false);
            return res.json();
          })
          .catch((err) => {
            setIsRequestLoading(false);
          });
      }}
      className={twMerge(
        "group flex w-min items-center gap-1.5 rounded-3xl px-2 py-1 text-lg leading-tight text-white transition-all duration-300",
        `${isPaused ? "bg-orange-300 hover:bg-orange-400" : "bg-green-500 hover:bg-green-600"}`,
      )}
    >
      <div className={twMerge("flex items-center")}>
        {isRequestLoading ? (
          <SimpleSpinner radius={20} />
        ) : isPaused ? (
          <PlayCircleIcon width={20} />
        ) : (
          <PauseCircleIcon width={20} />
        )}
      </div>
      <div className="">{isPaused === true ? "Resume" : "Pause"}</div>
    </button>
  );
};
