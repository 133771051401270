import { useState, useEffect } from "react";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import firebase_app from "../firebase/config";
import { Chat, UserChat } from "../models/data";

const useChatDetails = (chatId: string | null) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<UserChat | null>(null);

  useEffect(() => {
    if (!chatId) {
      return;
    }
    // Create a reference to the Firestore collection
    const chatDocRef = doc(getFirestore(firebase_app), `/chats/${chatId}`);

    const unsubscribe = onSnapshot(chatDocRef, (snapshot) => {
      if (snapshot.exists()) {
        setLoading(false);
        const chatData = snapshot.data();
        chatData.chat_id = snapshot.id;
        const chat = new UserChat(chatData as Chat);

        setData(chat);
      } else {
        setLoading(false);
        setData(null);
      }
    });
    return () => unsubscribe();
  }, [chatId]);

  return { chatDetailsLoading: loading, chatDetails: data };
};

export default useChatDetails;
