import * as React from "react";
const KurationLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={1087}
    height={75}
    viewBox="0 0 1087 175"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M239.138 113.545C239.138 123.693 236.983 132.029 232.674 138.555C228.364 145.081 221.696 148.346 212.668 148.352C203.215 148.352 196.543 145.677 192.653 140.326C188.763 134.976 186.818 127.577 186.818 118.129V54.1399H156.385V127.087C156.385 140.984 159.928 152.135 167.013 160.542C174.099 168.949 184.447 173.152 198.058 173.152C208.479 173.152 217.199 169.886 224.218 163.354C231.237 156.823 236.274 147.387 239.33 135.049V170.897H269.553V54.1399H239.138V113.545Z"
      fill="#000001"
    />
    <path
      d="M357.822 54.1399C347.894 54.1399 340.455 57.2106 335.506 63.353C330.557 69.4953 327.17 78.5769 325.343 90.5995V55.8108H294.928V173.152H325.526V108.66C325.526 100.139 327.446 93.6429 331.288 89.1751C335.129 84.7074 340.263 82.473 346.691 82.473C350.665 82.4219 354.623 82.9853 358.425 84.144C362.093 85.3566 365.555 87.1243 368.688 89.3851L377.91 59.2532C375.1 57.6023 372.064 56.3715 368.898 55.6008C365.284 54.6421 361.561 54.1518 357.822 54.1399Z"
      fill="#000001"
    />
    <path
      d="M868.825 79.3777C862.421 71.9269 853.309 68.2015 841.487 68.2015C831.893 68.2015 823.862 71.0659 817.39 76.7937C810.919 82.5215 806.333 90.9256 803.63 102.004V70.2286H776.849V173.672H803.813V120.659C803.813 111.43 805.812 104.043 809.812 98.4978C813.811 92.9526 819.877 90.1796 828.01 90.1796C836.501 90.1796 842.534 92.5509 846.107 97.2926C849.68 102.034 851.467 108.59 851.467 116.96V173.672H878.394V109.026C878.394 96.7109 875.205 86.8286 868.825 79.3777Z"
      fill="#000001"
    />
    <path
      d="M567.614 136.601C567.607 138.047 567.466 139.49 567.194 140.911C566.927 142.346 566.355 143.708 565.514 144.901C564.612 146.147 563.421 147.156 562.044 147.841C560.242 148.687 558.262 149.084 556.273 149.001C552.201 149.001 549.295 147.704 547.553 145.111C545.806 142.542 544.888 139.497 544.924 136.391V89.3395H592.405V68.1284H544.924V26.1263H516.362V68.1284H501.652V89.3395H516.152V97.6395C516.152 101.493 516.186 105.8 516.253 110.56C516.319 115.32 516.356 120.117 516.362 124.95V136.601C516.389 141.102 517.242 145.558 518.882 149.749C520.546 154.135 523.081 158.139 526.333 161.519C529.869 165.113 534.084 167.969 538.733 169.92C544.292 172.204 550.265 173.311 556.273 173.17C562.319 173.311 568.338 172.314 574.015 170.23C578.604 168.521 582.765 165.828 586.205 162.341C589.355 159.04 591.754 155.097 593.235 150.781C594.802 146.219 595.584 141.424 595.546 136.601V128.831H567.614V136.601Z"
      fill="#000001"
    />
    <path
      d="M638.653 69.2241H611.753V173.663H638.653V69.2241Z"
      fill="#000001"
    />
    <path
      d="M624.81 27.6603C619.715 27.6603 615.597 29.2217 612.456 32.3444C609.315 35.4672 607.745 39.6245 607.745 44.8172C607.745 50.0036 609.315 54.1244 612.456 57.1805C615.597 60.2366 619.715 61.7578 624.81 61.7459C630.027 61.7459 634.209 60.2238 637.356 57.1805C640.504 54.1371 642.077 50.0155 642.077 44.8172C642.077 39.6309 640.504 35.4736 637.356 32.3444C634.209 29.2153 630.027 27.6539 624.81 27.6603Z"
      fill="#000001"
    />
    <path
      d="M483.938 159.41C482.994 155.545 482.571 151.572 482.678 147.595V86.6276C482.624 81.9937 481.695 77.4118 479.939 73.123C478.113 68.2042 473.684 63.773 466.654 59.8284C459.762 56.0418 449.567 54.1463 436.065 54.1399C431.324 54.1234 426.593 54.5453 421.93 55.3999C417.964 56.0574 414.075 57.1193 410.325 58.5683C403.89 61.0958 398.422 65.6019 394.711 71.4338C391.059 77.2045 388.81 84.658 387.963 93.7953H416.443C417.143 87.611 419.042 83.3925 422.14 81.1399C425.512 79.0279 430.155 77.9715 436.065 77.9715C447.88 77.9715 453.788 82.5369 453.788 91.6678C453.788 96.1721 450.553 98.9114 444.082 99.8856L416.863 103.894C407.44 105.3 399.635 108.889 393.451 114.659C387.267 120.43 384.171 128.952 384.165 140.226C384.165 147.823 386.064 154.084 389.862 159.008C393.515 164.201 397.943 167.716 403.148 169.554C408.416 171.478 413.976 172.476 419.584 172.504C424.31 172.577 429.016 171.864 433.508 170.394C437.491 168.896 441.308 166.987 444.895 164.697C448.459 162.288 451.719 159.455 454.601 156.26C454.703 158.904 455.129 161.526 455.87 164.067C456.996 170.111 468.105 171.937 489.198 169.545V165.117C486.556 164.289 484.549 162.11 483.938 159.41ZM453.806 129.278C453.895 133.792 452.398 138.197 449.579 141.723C446.879 144.849 443.473 147.287 439.644 148.836C437.667 149.655 435.619 150.291 433.527 150.736C431.807 151.202 430.029 151.418 428.249 151.375C424.03 151.375 420.585 150.249 417.913 147.996C415.235 145.884 413.898 142.789 413.904 138.71C413.904 133.652 415.31 129.856 418.123 127.324C421.243 124.786 424.935 123.045 428.879 122.256L443.014 119.307C447.652 118.321 451.241 116.775 453.779 114.669L453.806 129.278Z"
      fill="#000001"
    />
    <path
      d="M736.262 76.1362C719.27 66.9295 698.724 66.9295 681.732 76.1362C673.755 80.5519 667.102 87.0221 662.466 94.8728C653.128 110.896 653.128 130.768 662.466 146.791C667.102 154.642 673.755 161.112 681.732 165.528C698.727 174.722 719.268 174.722 736.262 165.528C744.194 161.083 750.81 154.618 755.437 146.791C764.775 130.768 764.775 110.896 755.437 94.8728C750.81 87.0458 744.194 80.5811 736.262 76.1362ZM730.473 134.647C728.326 138.602 725.176 141.924 721.342 144.28C713.775 148.856 704.256 148.856 696.689 144.28C692.856 141.923 689.706 138.601 687.558 134.647C682.97 126.101 682.97 115.8 687.558 107.254C689.724 103.286 692.867 99.9359 696.689 97.5208C704.214 92.7992 713.817 92.7992 721.342 97.5208C725.164 99.935 728.307 103.286 730.473 107.254C735.072 115.796 735.072 126.105 730.473 134.647Z"
      fill="#000001"
    />
    <path
      d="M136.68 0.00280762H107.388C107.388 0.0183301 107.388 0.0329396 107.388 0.0484621C107.388 1.55141 106.152 2.78773 104.649 2.78773V32.0796C106.161 32.1088 107.388 33.3616 107.388 34.8737H136.68C136.68 34.8709 136.68 34.8673 136.68 34.8646C136.68 33.337 137.937 32.0796 139.465 32.0796V2.78773C139.449 2.78773 139.434 2.78773 139.419 2.78773C137.916 2.78773 136.68 1.55141 136.68 0.0484621C136.68 0.0338526 136.68 0.0183301 136.68 0.00280762Z"
      fill="#000001"
    />
    <path
      d="M72.5265 69.7355H101.809C101.809 68.2079 103.067 66.9506 104.594 66.9506C104.597 66.9506 104.601 66.9506 104.603 66.9506V37.6587C103.074 37.6587 101.814 36.4032 101.809 34.8738H72.5265C72.5219 36.4032 71.2619 37.6587 69.7324 37.6587V66.9506C69.7352 66.9506 69.7388 66.9506 69.7415 66.9506C71.2692 66.9506 72.5265 68.2079 72.5265 69.7355Z"
      fill="#000001"
    />
    <path
      d="M69.7322 72.5295C68.2046 72.5295 66.9473 71.2722 66.9473 69.7446C66.9473 69.7418 66.9473 69.7382 66.9473 69.7354H37.6553C37.6553 71.2649 36.3998 72.5249 34.8704 72.5295V1.36331C34.1244 1.36331 33.5099 0.748801 33.5099 0.00280762H1.3599C1.3599 0.748801 0.745386 1.36331 -0.000610352 1.36331V173.024C0.745386 173.024 1.3599 173.639 1.3599 174.385H33.5099C33.5099 173.639 34.1244 173.024 34.8704 173.024V101.812C36.3998 101.817 37.6553 103.077 37.6553 104.606H66.9473C66.9473 104.604 66.9473 104.6 66.9473 104.597C66.9473 103.07 68.2046 101.812 69.7322 101.812V72.5295Z"
      fill="#000001"
    />
    <path
      d="M104.603 107.391C103.091 107.391 101.839 106.164 101.809 104.652H72.5265C72.4973 106.164 71.2445 107.391 69.7324 107.391V136.683C69.7352 136.683 69.7388 136.683 69.7415 136.683C71.2692 136.683 72.5265 137.94 72.5265 139.468H101.809C101.809 137.94 103.067 136.683 104.594 136.683C104.597 136.683 104.601 136.683 104.603 136.683V107.391Z"
      fill="#000001"
    />
    <path
      d="M136.68 139.468H107.388C107.388 140.98 106.161 142.233 104.649 142.262V171.545C106.161 171.574 107.388 172.827 107.388 174.339H136.68C136.68 174.336 136.68 174.332 136.68 174.33C136.68 172.802 137.937 171.545 139.465 171.545V142.262C137.937 142.262 136.68 141.005 136.68 139.477C136.68 139.474 136.68 139.471 136.68 139.468Z"
      fill="#000001"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1085.25 69.5577C1085.25 54.8269 1073.29 42.866 1058.55 42.866H980.058C965.325 42.866 953.364 54.8269 953.364 69.5577V145.153C953.364 159.887 965.325 171.848 980.058 171.848H1058.55C1073.29 171.848 1085.25 159.887 1085.25 145.153V69.5577Z"
      stroke="#6C72FF"
      strokeWidth={2.00515}
      strokeMiterlimit={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1034.74 133.288H1023.26L1018.69 121.416H997.809L993.495 133.288H982.301L1002.66 81.0259H1013.82L1034.74 133.288ZM1015.31 112.612L1008.11 93.2202L1001.05 112.612H1015.31Z"
      fill="#6C72FF"
    />
    <path
      d="M1050.56 81.0259H1040.01V133.283H1050.56V81.0259Z"
      fill="#6C72FF"
    />
  </svg>
);

export default KurationLogo;
