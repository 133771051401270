import * as React from "react";
const DownloadIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.75 17.25C4.94891 17.25 5.13968 17.329 5.28033 17.4697C5.42098 17.6103 5.5 17.8011 5.5 18V20.25C5.5 20.388 5.612 20.5 5.75 20.5H18.25C18.3163 20.5 18.3799 20.4737 18.4268 20.4268C18.4737 20.3799 18.5 20.3163 18.5 20.25V18C18.5 17.8011 18.579 17.6103 18.7197 17.4697C18.8603 17.329 19.0511 17.25 19.25 17.25C19.4489 17.25 19.6397 17.329 19.7803 17.4697C19.921 17.6103 20 17.8011 20 18V20.25C20 20.7141 19.8156 21.1592 19.4874 21.4874C19.1592 21.8156 18.7141 22 18.25 22H5.75C5.28587 22 4.84075 21.8156 4.51256 21.4874C4.18437 21.1592 4 20.7141 4 20.25V18C4 17.8011 4.07902 17.6103 4.21967 17.4697C4.36032 17.329 4.55109 17.25 4.75 17.25Z"
      fill="black"
    />
    <path
      d="M5.21975 9.97C5.28932 9.90034 5.37194 9.84507 5.46288 9.80736C5.55382 9.76965 5.6513 9.75025 5.74975 9.75025C5.8482 9.75025 5.94569 9.76965 6.03663 9.80736C6.12757 9.84507 6.21019 9.90034 6.27975 9.97L11.2498 14.939V2.75C11.2498 2.55109 11.3288 2.36032 11.4694 2.21967C11.6101 2.07902 11.8008 2 11.9998 2C12.1987 2 12.3894 2.07902 12.5301 2.21967C12.6707 2.36032 12.7498 2.55109 12.7498 2.75V14.939L17.7198 9.97C17.8603 9.82944 18.051 9.75047 18.2498 9.75047C18.4485 9.75047 18.6392 9.82944 18.7798 9.97C18.9203 10.1106 18.9993 10.3012 18.9993 10.5C18.9993 10.6988 18.9203 10.8894 18.7798 11.03L12.5298 17.28C12.4602 17.3497 12.3776 17.4049 12.2866 17.4426C12.1957 17.4803 12.0982 17.4998 11.9998 17.4998C11.9013 17.4998 11.8038 17.4803 11.7129 17.4426C11.6219 17.4049 11.5393 17.3497 11.4698 17.28L5.21975 11.03C5.15009 10.9604 5.09482 10.8778 5.05712 10.7869C5.01941 10.6959 5 10.5984 5 10.5C5 10.4016 5.01941 10.3041 5.05712 10.2131C5.09482 10.1222 5.15009 10.0396 5.21975 9.97Z"
      fill="black"
    />
  </svg>
);
export default DownloadIcon;
