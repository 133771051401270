import * as React from "react";
const DeleteIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.4531 4.5V4.54688H16.5H20.25C20.4365 4.54688 20.6153 4.62095 20.7472 4.75282C20.879 4.88468 20.9531 5.06352 20.9531 5.25C20.9531 5.43648 20.879 5.61532 20.7472 5.74718C20.6153 5.87905 20.4365 5.95312 20.25 5.95312H19.5H19.4531V6V19.5C19.4531 19.8854 19.3 20.255 19.0275 20.5275C18.755 20.8 18.3854 20.9531 18 20.9531H6C5.61461 20.9531 5.245 20.8 4.97249 20.5275C4.69997 20.255 4.54688 19.8854 4.54688 19.5V6V5.95312H4.5H3.75C3.56352 5.95312 3.38468 5.87905 3.25282 5.74718C3.12095 5.61532 3.04688 5.43648 3.04688 5.25C3.04688 5.06352 3.12095 4.88468 3.25282 4.75282C3.38468 4.62095 3.56352 4.54688 3.75 4.54688H7.5H7.54688V4.5V3.75C7.54688 3.16569 7.77899 2.60532 8.19215 2.19216C8.60532 1.77899 9.1657 1.54688 9.75 1.54688H14.25C14.8343 1.54688 15.3947 1.77899 15.8078 2.19216C16.221 2.60532 16.4531 3.16569 16.4531 3.75V4.5ZM15 4.54688H15.0469V4.5V3.75C15.0469 3.53866 14.9629 3.33597 14.8135 3.18652C14.664 3.03708 14.4613 2.95312 14.25 2.95312H9.75C9.53866 2.95312 9.33597 3.03708 9.18652 3.18652C9.03708 3.33597 8.95312 3.53866 8.95312 3.75V4.5V4.54688H9H15ZM18 19.5469H18.0469V19.5V6V5.95312H18H6H5.95312V6V19.5V19.5469H6H18ZM10.4531 9.75V15.75C10.4531 15.9365 10.379 16.1153 10.2472 16.2472C10.1153 16.379 9.93648 16.4531 9.75 16.4531C9.56352 16.4531 9.38468 16.379 9.25282 16.2472C9.12095 16.1153 9.04688 15.9365 9.04688 15.75V9.75C9.04688 9.56352 9.12095 9.38468 9.25282 9.25282C9.38468 9.12095 9.56352 9.04688 9.75 9.04688C9.93648 9.04688 10.1153 9.12095 10.2472 9.25282C10.379 9.38468 10.4531 9.56352 10.4531 9.75ZM14.9531 9.75V15.75C14.9531 15.9365 14.879 16.1153 14.7472 16.2472C14.6153 16.379 14.4365 16.4531 14.25 16.4531C14.0635 16.4531 13.8847 16.379 13.7528 16.2472C13.621 16.1153 13.5469 15.9365 13.5469 15.75V9.75C13.5469 9.56352 13.621 9.38468 13.7528 9.25282C13.8847 9.12095 14.0635 9.04688 14.25 9.04688C14.4365 9.04688 14.6153 9.12095 14.7472 9.25282C14.879 9.38468 14.9531 9.56352 14.9531 9.75Z"
      fill="#FF2F2F"
      stroke="#FF2F2F"
      strokeWidth={0.09375}
    />
  </svg>
);
export default DeleteIcon;
