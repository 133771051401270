import { useState, useEffect } from "react";
import {
  getFirestore,
  getDoc,
  doc
} from "firebase/firestore";
import firebase_app from "../firebase/config";

const useOnboarding = (userId: string | null) => {
  const [onBoarding, setOnboarding] = useState<boolean | null>(null); // Start with `null` to represent the loading state
  const db = getFirestore(firebase_app);

  useEffect(() => {
    const fetchOnboardingStatus = async () => {
      if (!userId) {
        setOnboarding(false); // Default to false if no userId
        return;
      }

      try {
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const data = userDoc.data();
          setOnboarding(data.onBoarding ?? false); // Set to the `onBoarding` value, or false if undefined
        } else {
          setOnboarding(false); // Default to false if the document does not exist
        }
      } catch (error) {
        setOnboarding(false); 
      }
    };

    fetchOnboardingStatus();
  }, [userId, db]);

  return { onBoarding };
};

export default useOnboarding;
