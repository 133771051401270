import {
  collection,
  getFirestore,
  query,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  DocumentSnapshot,
  onSnapshot,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import firebase_app from "src/firebase/config";
import { TableRow } from "src/models/data";

const useTableRows = ({
  tableId,
  pageSize,
}: {
  tableId: string;
  pageSize: number;
}) => {
  const { chatId } = useParams();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<TableRow[]>([]);
  const [page, setPage] = useState(1);
  const [direction, setDirection] = useState<"prev" | "next" | undefined>(
    "next",
  );
  const [firstDoc, setFirstDoc] = useState<DocumentSnapshot | undefined>(
    undefined,
  );
  const [lastDoc, setLastDoc] = useState<DocumentSnapshot | undefined>(
    undefined,
  );

  const numPerPage = pageSize;
  function fetchPage() {
    setLoading(true);

    const startAfterDoc = direction === "next" ? lastDoc : undefined;
    const endBeforeDoc = direction === "prev" ? firstDoc : undefined;

    const collectionS = `/chats/${chatId}/tables/${tableId}/rows`;
    const dataCollection = collection(getFirestore(firebase_app), collectionS);
    // Define the initial data query with ordering and limit
    let dataQuery = query(
      dataCollection,
      orderBy("index"),
      limit(numPerPage),
    );

    // Update query based on direction and provided documents
    if (direction === "next" && startAfterDoc) {
      // For next direction, start after the provided document
      dataQuery = query(dataQuery, startAfter(startAfterDoc));
    } else if (direction === "prev" && endBeforeDoc) {
      // For previous direction, end before the provided document and limit to last
      dataQuery = query(
        dataCollection,
        orderBy("index"),
        endBefore(endBeforeDoc),
        limitToLast(numPerPage),
      );
    }

    const unsubscribe = onSnapshot(
      dataQuery,
      (snapshot) => {
        const allRows: TableRow[] = [];
        const allRowSnapshots: DocumentSnapshot[] = [];
        snapshot.forEach((row) => {
          allRowSnapshots.push(row);
          const rowData = { id: row.id, ...row.data() };

          allRows.push(new TableRow(rowData));
        });
        setLoading(false);
        setData(allRows);
        setFirstDoc(allRowSnapshots[0]);
        setLastDoc(allRowSnapshots[allRowSnapshots.length - 1]);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      },
    );
    return unsubscribe;
  }
  useEffect(() => {
    const unsubscribe = fetchPage();
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, tableId, page]);

  function getNextPage() {
    setDirection("next");
    setPage(page + 1);
  }
  function getPreviousPage() {
    setDirection("prev");
    setPage(page - 1);
  }
  return { allRows: data, loading, getNextPage, getPreviousPage, page };
};

export default useTableRows;
